export type TYPOGRAPHY_SIZE =
    | 'tiny16pxLH'
    | 'tiny'
    | 'small'
    | 'small24px'
    | 'small24pxLH'
    | 'regular'
    | 'regular24pxLH'
    | 'medium'
    | 'large'
    | 'huge';
export type TYPOGRAPHY_WEIGHT = 'regular' | 'dark' | 'bold';

const typography = {
    fontFamily: '"Aeonik", Arial, sans-serif',
    fontWeight: {
        regular: 400,
        dark: 500,
        bold: 700,
    },
    // The naming convention is fontsize+lineHeight, where no lineHeight means the default lineHeight
    fontSize: {
        tiny: '12px',
        tiny16pxLH: '12px',
        small: '16px',
        small24px: '16px',
        small24pxLH: '16px',
        regular: '20px',
        regular24pxLH: '20px',
        medium: '24px',
        large: '40px',
        huge: '72px',
    },
    lineHeight: {
        tiny: '20px',
        tiny16pxLH: '16px',
        small: '20px',
        small24px: '24px',
        small24pxLH: '24px',
        regular24pxLH: '24px',
        regular: '32px',
        medium: '36px',
        large: '56px',
        huge: '86px',
    },
};

export default typography;
