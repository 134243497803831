import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../theme';
import clsx from 'clsx';
import { spacingFromTheme } from '../utils';

const useBaseStyles = createUseStyles({
    root: {
        border: 'none',
        boxSizing: 'border-box',
    },
});
//eslint-disable-next-line @typescript-eslint/no-explicit-any
const useOverrideStyles: any = createUseStyles({
    horizontal: ({ mainSize = '100%', crossSize = 1, mainMargin = 'auto', crossMargin = 'auto' }: DividerProps) => ({
        width: mainSize,
        height: crossSize,
        marginInlineStart: spacingFromTheme(mainMargin),
        marginInlineEnd: spacingFromTheme(mainMargin),
        marginBlockStart: spacingFromTheme(crossMargin),
        marginBlockEnd: spacingFromTheme(crossMargin),
    }),
    vertical: ({ mainSize = '100%', crossSize = 1, mainMargin = 'auto', crossMargin = 'auto' }: DividerProps) => ({
        width: crossSize,
        height: mainSize,
        marginInlineStart: spacingFromTheme(crossMargin),
        marginInlineEnd: spacingFromTheme(crossMargin),
        marginBlockStart: spacingFromTheme(mainMargin),
        marginBlockEnd: spacingFromTheme(mainMargin),
    }),
});

interface DividerProps {
    variant: 'horizontal' | 'vertical';
    mainSize?: number | string;
    crossSize?: number | string;
    mainMargin?: number | string;
    crossMargin?: number | string;
    dividerColor?: string;
}

const Divider = ({
    variant,
    dividerColor = theme.palette.text.blackLite,
    ...otherProps
}: DividerProps): JSX.Element => {
    const baseClasses = useBaseStyles();
    const overrideClasses = useOverrideStyles(otherProps);

    return (
        <hr
            className={clsx(
                baseClasses.root,
                variant === 'horizontal' ? overrideClasses.horizontal : overrideClasses.vertical,
            )}
            style={{ backgroundColor: dividerColor }}
        />
    );
};

export default Divider;
