const white = 'rgba(255, 255, 255, 0.9)';

const fullWhite = 'rgba(255, 255, 255, 1)';

const black = 'rgba(0, 0, 0, 0.9)';

const fullBlack = 'rgba(0, 0, 0, 1)';

const text = {
    black: black,
    white: white,
    blackOverlay: 'rgba(0, 0, 0, 0.6)',
    whiteOverlay: 'rgba(255, 255, 255, 0.6)',
    blackLite: 'rgba(0, 0, 0, 0.15)',
    whiteLite: 'rgba(255, 255, 255, 0.15)',
    blackLiteOpaque: '#D9D9D9',
    disabled: '#CDCDCD',
};

const common = {
    white,
    fullWhite,
    black,
    fullBlack,
};

const surface = {
    cream: '#F4F3EF',
    creamLite: '#FDFCFB',
    green: '#E5F3DE',
    blue: '#D0E2F2',
    disabled: '#EFEFEF',
    greenDisabled: '#74C5A0',
};

const icons = {
    blackOverlaySolid: '#666666',
    blackLiteSolid: 'rgba(217, 217, 217, 1)',
    lightGray: '#C4C4C4',
};

const signature = {
    green: '#179F61',
    greenDark: 'rgb(25, 134, 84)',
    blue: '#156DBE',
    blueDark: 'rgb(24, 94, 159)',
    red: '#CC0000',
    orange: '#F09130',
    purple: '#9A78D1',
};

const ui = {
    red: '#EB9999',
    green: '#E5F3DE',
};

export default {
    common,
    icons,
    signature,
    surface,
    text,
    ui,
};
