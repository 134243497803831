import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { AuditLogPanel } from './components';
import { useStores } from '../../hooks/use-stores';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        minHeight: '78vh',
    },
    menu: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(3),
    },
}));

const AuditPage = observer(() => {
    const { marketStore } = useStores();
    useEffect(() => {
        marketStore.load();

        marketStore.loadAuditLogs();
    }, [marketStore]);

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.menu}>
                <Typography variant="h5">Global Audit Trail</Typography>
            </div>
            <AuditLogPanel
                showTitle={false}
                logs={marketStore.auditLogs}
                minWidth="90vw"
                maxWidth="90vw"
                minHeight={'75vh'}
                refresh={marketStore.loadAuditLogs}
            />
        </div>
    );
});

export default AuditPage;
