import * as React from 'react';
import { observer } from 'mobx-react';
import DashboardComponent from '../../../components/DashboardComponent';
import { Box, Typography, IconButton, Button, TextField } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import PrevIcon from '@material-ui/icons/NavigateBefore';
import NextIcon from '@material-ui/icons/NavigateNext';
import { useState } from 'react';
import OperationLog from './Operation';
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AuditEntry } from '../../../models';

interface AuditLogPanelProps {
    logs: AuditEntry[];
    showTitle?: boolean;
    refresh?: () => void;
    [extras: string]: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ml: {
            marginLeft: theme.spacing(2),
        },
        bigMl: {
            marginLeft: theme.spacing(5),
        },
        dateTimePickers: {
            width: '180px',
        },
    }),
);

const uniqueLogs = (logs: AuditEntry[]) => {
    const hist: any = {};
    return logs.filter((log) => {
        if (!!log.created_ts) {
            const key = `ct-${log.created_ts}-${log.type}`;
            if (key in hist) {
                return false;
            }
            hist[key] = true;
        }
        return true;
    });
};

const ORDER_OPERATIONS = [
    'order_placed',
    'order_updated',
    'order_canceled',
    'order_auto_canceled',
    'order_fcc_canceled',
];

const AuditLogPanel = ({ logs, refresh, showTitle = true, ...rest }: AuditLogPanelProps) => {
    console.log(logs);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [minDate, setMinDate] = useState<Date | null>(null);
    const [maxDate, setMaxDate] = useState<Date | null>(null);
    const [ordersOnly, setOrdersOnly] = useState<boolean | null>(false);
    const classes = useStyles();

    if (!!logs) {
        const next = () => {
            setPage((page + 1) % totalPages);
        };

        const prev = () => {
            setPage((page - 1 + totalPages) % totalPages);
        };

        const reload = () => {
            if (!!refresh) refresh();
            setPage(0);
        };

        const reset = () => {
            setMinDate(null);
            setMaxDate(null);
            setOrdersOnly(false);
            reload();
        };

        const filteredLogs = uniqueLogs(logs)
            .filter((log) => {
                if (ordersOnly && !ORDER_OPERATIONS.includes(log.type)) {
                    return false;
                }
                const logDate = new Date(log.created_ts);
                const afterMin = minDate == null || minDate <= logDate;
                const beforeMax = maxDate == null || maxDate >= logDate;
                return afterMin && beforeMax;
            })
            .sort((a, b) => Date.parse(b.created_ts) - Date.parse(a.created_ts));

        const totalPages = Math.max(Math.ceil(filteredLogs.length / pageSize), 1);

        const currentPage = filteredLogs.slice(page * pageSize, (page + 1) * pageSize);

        return (
            <DashboardComponent display={'flex'} flexDirection={'column'} {...rest}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box mt={1} mb={1} justifyContent={'flex-start'} display={'flex'} alignItems={'center'}>
                        {showTitle && <Typography variant={'h5'}>Audit Trail</Typography>}
                        <Box ml={4} display={'flex'} alignItems={'center'}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    margin={'dense'}
                                    className={classes.dateTimePickers}
                                    value={minDate}
                                    size={'small'}
                                    variant="inline"
                                    onChange={(event) => {
                                        setMinDate(event as Date);
                                    }}
                                    inputVariant={'outlined'}
                                    label="Min Timestamp"
                                />
                                <DateTimePicker
                                    className={clsx(classes.bigMl, classes.dateTimePickers)}
                                    margin={'dense'}
                                    value={maxDate}
                                    size={'small'}
                                    variant="inline"
                                    onChange={(event) => {
                                        setMaxDate(event as Date);
                                    }}
                                    inputVariant={'outlined'}
                                    label="Max Timestamp"
                                />
                            </MuiPickersUtilsProvider>
                            <Button
                                className={classes.bigMl}
                                size="medium"
                                color={'primary'}
                                style={{ height: '40px', marginTop: '4px' }}
                                variant={ordersOnly ? 'contained' : 'outlined'}
                                onClick={() => {
                                    setOrdersOnly(!ordersOnly);
                                }}
                            >
                                Orders/Trades only
                            </Button>
                            <Button
                                className={classes.bigMl}
                                size="medium"
                                style={{ height: '40px', marginTop: '4px' }}
                                color={'primary'}
                                variant={'outlined'}
                                onClick={reset}
                            >
                                CLEAR FILTERS
                            </Button>
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                        <TextField
                            label="Pagesize"
                            type={'number'}
                            inputProps={{ min: 1, max: 100, step: 1 }}
                            value={pageSize}
                            onChange={(e) => {
                                const inputNumber = Number(e.target.value);
                                if (!isNaN(inputNumber) && inputNumber > 0 && inputNumber <= 100) {
                                    setPageSize(inputNumber);
                                }
                            }}
                        />
                        <Box ml={2} display={'flex'} alignItems={'center'}>
                            <IconButton
                                className={classes.ml}
                                size={'medium'}
                                aria-label="tasks-box-next"
                                onClick={prev}
                            >
                                <PrevIcon />
                            </IconButton>
                            <Box ml={2}>
                                <Typography>
                                    {page + 1}/{totalPages}
                                </Typography>
                            </Box>
                            <IconButton
                                className={classes.ml}
                                size={'medium'}
                                aria-label="tasks-box-prev"
                                onClick={next}
                            >
                                <NextIcon />
                            </IconButton>
                        </Box>
                        {!!refresh && (
                            <IconButton
                                className={classes.ml}
                                size={'medium'}
                                aria-label="tasks-box-next"
                                onClick={reload}
                            >
                                <RefreshIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>

                <Box mt={2}>
                    {currentPage.map((log) => (
                        <OperationLog key={`log-${log.created_ts}`} log={log} />
                    ))}
                </Box>
            </DashboardComponent>
        );
    }

    return null;
};

export default AuditLogPanel;
