import React from 'react';
import { Box, Card, CardContent, colors, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface HeaderLinkCardProps {
    className?: string;
    name: string;
    count: number;
    onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    avatar: {
        backgroundColor: colors.red[600],
        height: 56,
        width: 56,
    },
    differenceIcon: {
        color: colors.red[900],
    },
    differenceValue: {
        color: colors.red[900],
        marginRight: theme.spacing(1),
    },
}));

const HeaderLinkCard = ({ className, name, count, onClick }: HeaderLinkCardProps): JSX.Element => {
    const classes = useStyles();
    return (
        <Card className={clsx(classes.root, className)} style={{ cursor: 'pointer' }} onClick={onClick}>
            <CardContent>
                <Grid container justify="space-between" spacing={3}>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h5">
                            {name}
                        </Typography>
                        <Typography color="textPrimary" variant="h4">
                            {count} active tasks
                        </Typography>
                    </Grid>
                </Grid>
                <Box mt={2} display="flex" alignItems="center">
                    <Typography color="textSecondary" variant="caption">
                        See More
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default HeaderLinkCard;
