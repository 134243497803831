import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReviewSection from '../../components/ReviewSection';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        appBar: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
        },
    }),
);

const TaskPane = (): JSX.Element => {
    const { taskId } = useParams<{ taskId: string }>();

    const history = useHistory();
    const { taskStore } = useStores();
    const classes = useStyles();
    const task = taskStore.resolveTask(taskId);

    useEffect(() => {
        taskStore.loadTask(taskId);
    }, [taskId]);

    return (
        <Dialog
            open={true}
            fullScreen
            onClose={() => {
                history.push('/');
            }}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6">Task Review</Typography>{' '}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            history.push('/');
                        }}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <ReviewSection task={task} />
        </Dialog>
    );
};

export default observer(TaskPane);
