import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { observer } from 'mobx-react';
import theme from './theme';
import Routes from './Routes';
import { useStores } from './hooks/use-stores';
import OnRoute from './OnRoute';

const App = observer(() => {
    const rootStore = useStores();
    const user = rootStore.userStore;

    useEffect(() => {
        user.load();

        return (): void => {
            rootStore.dispose();
        };
    }, [user, rootStore]);

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <OnRoute />
                <Routes />
            </Router>
        </ThemeProvider>
    );
});

export default App;
