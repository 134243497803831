const pluralize = (text: string, count: number, pluralText?: string): string => {
    if (pluralText !== undefined) {
        return count === 1 ? text : pluralText;
    }
    return text + (count === 1 ? '' : 's');
};

const toTitleCase = (text: string): string => {
    return text
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1))
        .join(' ');
};

const truncateToWordCount = (text: string, wordCount: number, addElipses = false): string => {
    const truncated = text.split(' ').slice(0, wordCount).join(' ');
    return addElipses && truncated !== text
        ? ['.', '!', '?'].some((punctuation) => truncated.endsWith(punctuation))
            ? truncated
            : truncated + '...'
        : truncated;
};

const asCents = (value: number): string => {
    return `${value}¢`;
};

const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const toMoney = (val: number): string => {
    return moneyFormatter.format(val);
};

const toMoneyWithSign = (val: number, withSpace = false): string => {
    return (val >= 0 ? '+' : '-') + (withSpace ? ' ' : '') + toMoney(Math.abs(val));
};

const toNumber = (val: number, precision = 2): string => {
    const numberFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    });
    return numberFormatter.format(val);
};

const toVariableLengthFloat = (val: number, precision = 2): string => {
    const numberFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: precision,
    });
    return numberFormatter.format(val);
};

const toFormattedDollarAmount = (value: string): string => {
    if (value.length === 0) {
        return '';
    }
    const dotPosition = value.indexOf('.');
    if (dotPosition === -1) {
        return toNumber(Number(value), 0);
    }
    const decimalPlaces = value.length - 1 - dotPosition;
    if (decimalPlaces === 0) {
        return toNumber(Number(value), 0) + '.';
    }
    return toNumber(Number(value), decimalPlaces);
};

const toCentsInteger = (valueInDollars: number): number => {
    return Math.round(valueInDollars * 100);
};

const toFormattedCents = (valueInDollars: number): string => {
    return asCents(toCentsInteger(valueInDollars));
};

/*
  toPercentage receives a percentual value between 1 and 0
  the input shouldn't be multiplied by 100 before calling toPercentage
 */
const toPercentage = (value: number, withSign: boolean, withSpace = false): string => {
    const sign = value >= 0.0 ? '+' : '-';
    return `${withSign ? sign : ''}${withSpace ? ' ' : ''}${Math.abs(value * 100).toFixed(2)}%`;
};

function toCommas(x: number): string {
    let xs = x.toString();
    const pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(xs)) xs = xs.replace(pattern, '$1,$2');
    return xs;
}

/*
   Receives a value in cents and the number of integer digits we want.
   Usually we want to show with two digits so that's the default.
  */
function asPaddedCents(valueInCents: number, numberOfDigits = 2): string {
    return asCents(valueInCents).padStart(numberOfDigits + 1, '0');
}

export {
    pluralize,
    toTitleCase,
    truncateToWordCount,
    toMoney,
    toMoneyWithSign,
    toPercentage,
    toNumber,
    toVariableLengthFloat,
    toCentsInteger,
    toFormattedCents,
    toFormattedDollarAmount,
    toCommas,
    asPaddedCents,
    asCents,
};
