import { action, computed, observable } from 'mobx';
import { CancelTokenSource } from 'axios';
import { RootStore } from '../root';
import { TransportLayer } from '../../transport';
import { AuditEntry, MarketsMap, OrderBookMap, TradeMap } from '../../models';
import { Market } from '../domain/Market';
import config from '../../utils/config';
import { OrderBook } from '../domain/Orderbook';
import WsService from '../../transport/ws';
import { Trade } from '../domain/Trade';

const noop = (): null => null;

export default class MarketStore {
    polling = false;
    @observable loading = false;
    timer: NodeJS.Timeout | null = null;
    fetchRef: CancelTokenSource | null = null;

    @observable markets: MarketsMap = {};
    @observable trades: TradeMap = {};
    @observable orderBooks: OrderBookMap = {};
    @observable auditLogs: Array<AuditEntry> = [];

    root: RootStore;
    ws: WsService;

    constructor(rootStore: RootStore, ws: WsService) {
        this.ws = ws;
        this.root = rootStore;
    }

    get transportLayer(): TransportLayer {
        return this.root.transportLayer;
    }

    @action load(onSuc: () => void = noop, onErr: () => void = noop): void {
        if (!this.loading) {
            this.loading = true;
            this.transportLayer.getMarkets(
                action((data) => {
                    data.forEach((vo) => {
                        if (!!this.markets[vo.id]) {
                            this.markets[vo.id].updateFromVo(vo);
                        } else {
                            this.markets[vo.id] = new Market(this, vo);
                        }
                    });
                    onSuc();
                }),
                (error) => {
                    console.error('Could not load markets from server.', error);
                    onErr();
                },
                action(() => {
                    this.loading = false;
                }),
            );
        }
    }

    @action loadAuditLogs(): void {
        this.load();
        const marketIdList = Object.keys(this.markets);
        const newAuditArray: Array<AuditEntry> = [];
        for (let i = 0; i < marketIdList.length; i++) {
            const marketId = marketIdList[i];
            this.transportLayer.getMarketAudit(
                marketId,
                action((data) => {
                    Array.prototype.push.apply(newAuditArray, data);
                    this.auditLogs = newAuditArray;
                }),
                (error) => {
                    console.error('Could not get audit logs from server', error);
                },
            );
        }
    }

    @action
    loadMarket(marketId: string, polling = false, pollingIntervalMS: number | null = null, getTrades: boolean): void {
        this.transportLayer.getMarket(
            marketId,
            action((marketVo) => {
                const market = this.resolveMarket(marketId).updateFromVo(marketVo);
                this.transportLayer.getMarketPriceHistory(market.id, 0, (prices) =>
                    market.updateStatsHistoryFromVo(prices),
                );

                if (getTrades) {
                    this.transportLayer.getMarketTrades(
                        marketId,
                        action((data) => {
                            const tradeList = data.map((vo) => new Trade(vo));
                            this.trades[marketId] = tradeList;
                        }),
                    );
                }

                if (polling) {
                    const trueInterval = pollingIntervalMS ?? config.pollingInterval;
                    market.startPolling(trueInterval);
                }
            }),
            (err) => {
                console.error('Could not load market from server.', err);
            },
        );
    }

    @action
    loadMarketStats(marketId: string): void {
        const market = this.resolveMarket(marketId);

        let lastTs = 0;
        if (market.statsHistory.length > 0) {
            lastTs = market.statsHistory[market.statsHistory.length - 1].ts;
        }

        this.transportLayer.getMarketPriceHistory(market.id, lastTs, (prices) =>
            market.appendStatsHistoryFromVo(prices),
        );
    }

    @action loadOrderbook(marketId: string): void {
        const orderBook = this.resolveOrderBook(marketId);
        this.transportLayer.getOrderBook(
            marketId,
            action((data) => {
                console.log(data);
                orderBook.parseRequest(data);
            }),
            (error) => {
                console.error('Could not get order book from server', error);
            },
        );
    }

    @computed get all(): Array<Market> {
        return Object.keys(this.markets)
            .map((id) => this.markets[id])
            .filter((market) => !!market.id);
    }

    @computed get openMarkets(): Array<Market> {
        return this.all.filter((q) => q.isOpen).sort((a, b) => b.title.localeCompare(a.title));
    }

    resolveMarket(id: string): Market {
        if (!this.markets[id]) {
            this.markets[id] = new Market(this);
        }
        return this.markets[id];
    }

    resolveOrderBook(marketId: string): OrderBook {
        if (!this.orderBooks[marketId]) {
            this.orderBooks[marketId] = new OrderBook(this, marketId);
        }
        return this.orderBooks[marketId];
    }

    startPolling(pollingIntervalMS: number | null = null, onSuc: () => void, onErr: () => void): void {
        const trueInterval = pollingIntervalMS ?? config.pollingInterval;

        if (!this.loading) {
            this.load(onSuc, onErr);
        }

        if (!this.polling) {
            this.polling = true;
            this.timer = setInterval(() => {
                if (!this.loading) {
                    this.load(onSuc, onErr);
                }
            }, trueInterval);
        }
    }

    @action stopPolling(): void {
        if (this.loading) {
            if (this.fetchRef !== null) {
                this.fetchRef.cancel('Markets fetch request aborted!');
                this.fetchRef = null;
            }
            this.loading = false;
        }
        if (this.polling) {
            if (this.timer !== null) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.polling = false;
        }
    }
}
