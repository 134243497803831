const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

export const toMoney = (val: number): string => {
    return moneyFormatter.format(val);
};

export const toNumber = (val: number, precision = 2): string => {
    const numberFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    });
    return numberFormatter.format(val);
};
