import React, { LabelHTMLAttributes, ReactNode } from 'react';
import theme from '../theme';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { TYPOGRAPHY_SIZE, TYPOGRAPHY_WEIGHT } from '../theme/typography';
import { getFromTheme } from '../utils';

export interface TypographyStyleProps {
    color?: string;
    hoverColor?: string;
    size?: TYPOGRAPHY_SIZE | string;
    weight?: TYPOGRAPHY_WEIGHT;
    className?: string;
    style?: React.CSSProperties;
    children: ReactNode;
}

export interface TypographyProps extends TypographyStyleProps {
    onClick?: (e: React.MouseEvent) => void;
    onMouseEnter?: (e: React.MouseEvent) => void;
    onMouseLeave?: (e: React.MouseEvent) => void;
    component?: 'span' | 'div' | 'label';
    labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
    href?: string;
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles: any = createUseStyles({
    root: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: ({ weight }: TypographyStyleProps): number =>
            theme.typography.fontWeight[weight as TYPOGRAPHY_WEIGHT],
        fontSize: ({ size }: TypographyStyleProps): string => theme.typography.fontSize[size as TYPOGRAPHY_SIZE],
        lineHeight: ({ size }: TypographyStyleProps): string => theme.typography.lineHeight[size as TYPOGRAPHY_SIZE],
        color: ({ color = 'text.black' }): string => getFromTheme('palette', color),
    },
    clickable: {
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            color: ({ hoverColor = 'signature.green' }: TypographyProps): string => getFromTheme('palette', hoverColor),
        },
    },
});

const Typography = ({
    size = 'small',
    weight = 'regular',
    style = {},
    className,
    onClick,
    onMouseEnter,
    onMouseLeave,
    href = undefined,
    component = 'span',
    children,
    labelProps,
    color = 'text.black',
    hoverColor = 'signature.green',
}: TypographyProps): JSX.Element => {
    const classes = useStyles({ weight, size, color, hoverColor });

    if (component === 'div') {
        return (
            <div
                className={clsx(classes.root, { [classes.clickable]: onClick !== undefined }, className)}
                style={style}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {children}
            </div>
        );
    }

    if (component === 'label') {
        return (
            <label
                className={clsx(classes.root, { [classes.clickable]: onClick !== undefined }, className)}
                style={style}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                {...labelProps}
            >
                {children}
            </label>
        );
    }

    return (
        <span
            className={clsx(
                classes.root,
                { [classes.clickable]: onClick !== undefined || href !== undefined },
                className,
            )}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={style}
        >
            {href !== undefined ? (
                <a
                    href={href}
                    className={clsx(
                        classes.root,
                        {
                            [classes.clickable]: onClick !== undefined || href !== undefined,
                        },
                        className,
                    )}
                    style={style}
                >
                    {children}
                </a>
            ) : (
                children
            )}
        </span>
    );
};

export default Typography;
