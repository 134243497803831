import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { UserVo } from '../../../models';
import { DashboardComponent, ValueDisplay } from '../../../components';
import { DashboardComponentProps } from '../../../components/DashboardComponent';

interface KycBoxProps extends DashboardComponentProps {
    user: UserVo;
}

const KycBox = ({ user, ...rest }: KycBoxProps): JSX.Element => {
    if (!!user) {
        return (
            <DashboardComponent display={'flex'} flexDirection={'column'} {...rest}>
                <Typography variant={'h6'}>KYC Info</Typography>
                <ValueDisplay mt={2} label={'Name'} value={`${user.first_name} ${user.last_name}`} />
                <ValueDisplay
                    label={'Address'}
                    value={`${user.street1} - ${user.street2} - ${user.city} - ${user.state} - ${user.country} / ${user.postal_code}`}
                />
                <ValueDisplay label={'Age'} value={String(35)} />
                <ValueDisplay label={'Email'} value={user.email} />
                <ValueDisplay label={'PEP'} value={'no'} />
                <ValueDisplay label={'KYC Risk Profile'} value={'low'} />
            </DashboardComponent>
        );
    }

    return (
        <DashboardComponent display={'flex'} flexDirection={'column'} {...rest}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant={'h6'}>[KYC Info]</Typography>
            </Box>
            <Box mt={2}>There are no kyc info for this trader at the moment.</Box>
        </DashboardComponent>
    );
};

export default KycBox;
