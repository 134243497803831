import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { RouteProps } from '../../Routes';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, TextField, Typography, Button } from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default observer((props: RouteProps) => {
    const classes = useStyles();
    const stores = useStores();
    const history = useHistory();
    const userStore = stores.userStore;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const validationError = (): string | null => {
        if (email === '' || password === '') {
            return 'Please fill email and password fields.';
        }

        return null;
    };

    const signIn = (e: React.FormEvent): void => {
        e.preventDefault();

        userStore.login(email, password);
    };

    if (userStore.isLoggedIn) {
        let to = '/';
        if (!!props.location.state && !!props.location.state.from) {
            to = props.location.state.from;
        }
        return <Redirect to={{ pathname: to, state: { from: props.location } }} />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} onSubmit={signIn}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(event): void => setEmail(event.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(event): void => setPassword(event.target.value)}
                    />
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        Sign In
                    </Button>
                </form>
            </div>
        </Container>
    );
});
