import React from 'react';
import { Helmet } from 'react-helmet';

interface PageProps {
    children: JSX.Element;
    className?: string;
    title?: string;
}

const Page = ({ children, className, title = '' }: PageProps): JSX.Element => {
    return (
        <div className={className}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {children}
        </div>
    );
};

export default Page;
