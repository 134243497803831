import React, { ReactNode, forwardRef, KeyboardEventHandler } from 'react';
import clsx from 'clsx';
import theme from '../theme';
import { createUseStyles } from 'react-jss';
import { getFromTheme } from '../utils';
import { Property } from 'csstype';

export interface BoxProps {
    p?: number;
    pt?: number;
    pl?: number;
    pr?: number;
    pb?: number;
    m?: number;
    mt?: number;
    ml?: number;
    mr?: number;
    mb?: number;
    color?: string;
    backgroundColor?: string;
    display?: Property.Display;
    flexDirection?: Property.FlexDirection;
    justifyContent?: Property.JustifyContent;
    alignItems?: Property.AlignItems;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
    onKeyPress?: KeyboardEventHandler<HTMLDivElement>;
    onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
    style?: React.CSSProperties;
    className?: string;
    children?: ReactNode;
    tabIndex?: number;
}

type SpacingKey =
    | 'padding'
    | 'paddingTop'
    | 'paddingLeft'
    | 'paddingRight'
    | 'paddingBottom'
    | 'margin'
    | 'marginTop'
    | 'marginLeft'
    | 'marginRight'
    | 'marginBottom';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const unwrapSpacing = (key: SpacingKey, val?: number): any => {
    if (val) {
        return { [key]: theme.spacing(val) };
    }
    return {};
};

type ColorKey = 'color' | 'backgroundColor';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const unwrapColor = (key: ColorKey, val?: string): any => {
    if (val) {
        return { [key]: getFromTheme('palette', val) };
    }
    return {};
};
//eslint-disable-next-line @typescript-eslint/no-explicit-any
const unwrap = (key: string, val?: any): any => {
    if (val) {
        return { [key]: val };
    }
    return {};
};

const useStyles = createUseStyles({
    root: {
        fontFamily: theme.typography.fontFamily,
    },
});

const Box = forwardRef(
    (
        {
            p = 0,
            pt = 0,
            pl = 0,
            pr = 0,
            pb = 0,
            m = 0,
            mt = 0,
            ml = 0,
            mr = 0,
            mb = 0,
            color,
            backgroundColor,
            display,
            flexDirection,
            justifyContent,
            alignItems,
            style = {},
            tabIndex,
            className,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onKeyPress,
            onKeyDown,
            children,
        }: BoxProps,
        ref: React.Ref<HTMLDivElement>,
    ) => {
        const classes = useStyles();

        return (
            <div
                ref={ref}
                className={clsx(classes.root, className)}
                style={{
                    ...unwrapSpacing('padding', p),
                    ...unwrapSpacing('paddingLeft', pl),
                    ...unwrapSpacing('paddingRight', pr),
                    ...unwrapSpacing('paddingTop', pt),
                    ...unwrapSpacing('paddingBottom', pb),
                    ...unwrapSpacing('margin', m),
                    ...unwrapSpacing('marginLeft', ml),
                    ...unwrapSpacing('marginRight', mr),
                    ...unwrapSpacing('marginTop', mt),
                    ...unwrapSpacing('marginBottom', mb),
                    ...unwrapColor('color', color),
                    ...unwrapColor('backgroundColor', backgroundColor),
                    ...unwrap('display', display),
                    ...unwrap('flexDirection', flexDirection),
                    ...unwrap('justifyContent', justifyContent),
                    ...unwrap('alignItems', alignItems),
                    ...style,
                }}
                tabIndex={tabIndex}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onKeyPress={onKeyPress}
                onKeyDown={onKeyDown}
            >
                {children}
            </div>
        );
    },
);

Box.displayName = 'Box';

export default Box;
