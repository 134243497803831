import * as React from 'react';
import './page.css';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';

export interface DashboardComponentProps {
    minWidth?: string;
    minHeight?: string;
    maxWidth?: string;
    maxHeight?: string;
    children?: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [extras: string]: any;
}

const DashboardComponent = ({
    children,
    maxWidth,
    maxHeight,
    minHeight,
    minWidth,
    ...rest
}: DashboardComponentProps): JSX.Element => {
    return (
        <Box
            className="dashboard_container"
            style={{
                maxWidth,
                minWidth,
                maxHeight,
                minHeight,
            }}
            {...rest}
        >
            {children}
        </Box>
    );
};

export default observer(DashboardComponent);
