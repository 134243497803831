import { OrderBookEntryVo } from '../../models';
import { action, computed, observable } from 'mobx';

export class OrderBookEntry {
    //Prices are handed out to clients in cents now
    @observable
    price = 0;
    @observable
    isYes = false;
    @observable
    count = 0;

    touched = false;

    @observable
    isUpdated = false;

    timer: NodeJS.Timeout | null = null;

    constructor(vo: OrderBookEntryVo | undefined = undefined) {
        if (!!vo) {
            this.updateFromVo(vo);
        }
    }

    toggleTimer(): void {
        this.isUpdated = true;
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.isUpdated = false;
        }, 1000);
    }

    /**
     * Update this entry with information
     */
    @action
    updateFromVo(vo: OrderBookEntryVo): OrderBookEntry {
        this.touched = true;

        if (vo.count !== this.count) {
            this.toggleTimer();
        }

        this.price = vo.price;
        if (vo.is_yes) {
            this.isYes = true;
        } else {
            this.isYes = false;
        }
        this.count = vo.count;
        return this;
    }

    @action
    reset(): void {
        this.count = 0;
    }

    @computed get priceInCents(): number {
        return this.price;
    }

    dispose(): void {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
}
