import config from '../utils/config';
import { arraysAreEqual } from '../utils/arrays';
import {
    SubscribedDTO,
    WsCommand,
    WsMessage,
    WsSubscribeParams,
    WsSubscriberCallback,
    WsSubscription,
    WsUnsubscribeCallback,
} from '../models/ws';
import { getLocalStorageWithExpiration } from '../utils/storage';
import { CSRF_LOCAL_STORAGE_KEY } from '../constants/session';

//TODO: Remove this suppression of non-null assertion
/* eslint-disable @typescript-eslint/no-non-null-assertion */

// Short pause for timeout duration as a class variable
// Todo: think about another way to deal with this without using delays
const FIXED_DELAY = 5000;
// Maximum delay for exponential backoff (2 ** MBE seconds)
const MAXIMUM_BACKOFF_EXPONENT = 5;
// Allowed time between command and response (milliseconds)
// Todo: think about another way to deal with this without using delays
const RESPONSE_DELAY = 5000;

class WsService {
    // single websocket instance for the entire application
    // if not connected, we are actively trying to reconnect
    private conn: WebSocket | null = null;
    private reconnectionTimer: NodeJS.Timeout | null = null;

    // map from Command ID to subscription response timeout timer
    private subscriptionResponseTimers = new Map<number, NodeJS.Timeout>();
    // map from Subscription ID to unsubscribe request timer (exponential backoff)
    private unsubscriptionResponseTimers = new Map<number, NodeJS.Timeout>();

    // incremented for each command sent
    private nextCommandId = 1;

    // ephemeral map from Command ID to subscription, pending server acknowledgement
    private pendingSubscriptions = new Map<number, WsSubscription>();
    // subscriptions we have lost interest in before receiving subscription confirmation
    private pendingUnsubscriptions = new Set<WsSubscription>();

    // map from Subscription ID to subscription
    private subscriptions = new Map<number, WsSubscription>();

    /**
     * @function connect
     * Externally available connection initiator. Will attempt to connect with exponential backoff.
     */
    connect(backoffExponent = 0): void {
        this.close(undefined, 'Closing websockets connection because of explicit client reconnection');

        console.log(`${config.exchangeWsUrl}/v1/ws`);
        const csrfToken = getLocalStorageWithExpiration(CSRF_LOCAL_STORAGE_KEY) || '';

        this.conn = new WebSocket(`${config.exchangeWsUrl}/v1/ws?csrfToken=${encodeURIComponent(csrfToken)}`);

        // websocket message listener
        this.conn.onmessage = this.handleMessage.bind(this);

        this.conn.onopen = (): void => {
            console.log('Websockets api connected');

            // stop retrying
            if (this.reconnectionTimer !== null) {
                clearTimeout(this.reconnectionTimer);
            }

            this.nextCommandId = 1;
            this.resubscribe();
        };

        // websocket onclose event listener
        this.conn.onclose = (e): void => {
            console.log('Socket is closed.', e.reason);
            if (e.code !== 1000) {
                console.log('Will attempt to reconnect to websocket');
                const { delay, random_ms, next_exp } = this.exponentialBackoff(backoffExponent);
                this.reconnectionTimer = setTimeout(() => this.connect(next_exp), delay + random_ms);
            }
        };

        console.log('weird error');

        // websocket onerror event listener
        this.conn.onerror = (err): void => {
            // We don't have to call onclose here as the websockets protocol will do it by itself.
            console.error('Socket encountered error: ', (err as ErrorEvent).message);
        };
    }

    /*
      4000–4999 is the range safe for application usage
     */
    close(code = 4000, reason: string): void {
        if (this.conn !== null) {
            if (this.conn.readyState === WebSocket.OPEN || this.conn.readyState === WebSocket.CONNECTING) {
                this.conn.close(code, reason);
            }
        }
    }

    dispose(): void {
        this.close(1000, 'Closing websockets because of client disposal');
    }

    /**
     * @function subscribe
     * Subscribes interested parties to a channel, and provides a callback to unsubscribe.
     */
    subscribe(params: WsSubscribeParams, subscriber: WsSubscriberCallback): WsUnsubscribeCallback {
        console.log('Subscribing with params', params);
        // to be captured in unsubscribeCallback closure
        let subscription: WsSubscription;

        const existingSubscription = this.matchingSubscription(params);
        if (existingSubscription != null) {
            // the requested subscription already exists, and this subscriber just needs to enroll
            subscription = existingSubscription.pending
                ? this.pendingSubscriptions.get(existingSubscription.id)!
                : this.subscriptions.get(existingSubscription.id)!;
        } else {
            // the requested subscription does not exist, and this subscriber needs to found it
            const commandId = this.nextCommandId++;
            const command: WsCommand = {
                id: commandId,
                cmd: 'subscribe',
                params: params,
            };
            this.pendingSubscriptions.set(commandId, { params, subscribers: new Set([subscriber]) });
            subscription = this.pendingSubscriptions.get(commandId)!;
            if (this?.conn?.readyState === WebSocket.OPEN) {
                this.conn?.send(JSON.stringify(command));
                // close the connection if we don't receive a subscription response in time
                const timeoutId = setTimeout(() => {
                    this.close(undefined, 'Closing websockets connection because of subscription response timeout');
                }, RESPONSE_DELAY);
                console.log('Setting subscription timeout', timeoutId);
                this.subscriptionResponseTimers.set(commandId, timeoutId);
            }
        }

        // callback used to unsubscribe
        return (): void => {
            console.log('Unsubscribing to', subscription);
            subscription.subscribers.delete(subscriber);
            if (subscription.subscribers.size == 0) {
                this.unsubscribe(subscription);
            }
        };
    }

    /**
     * @function handleMessage
     * Utilized by the @function connect to start listening to messages once the connection is established.
     */
    private handleMessage(evt: MessageEvent): void {
        const message: WsMessage = JSON.parse(evt.data);
        console.log('Received WS message ', message);

        switch (message.type) {
            case undefined:
                break;
            case 'subscribed':
                // stop waiting for a response
                const payload = message.msg as SubscribedDTO;
                const timeoutId = this.subscriptionResponseTimers.get(message.id!)!;
                console.log('Erasing timeout', timeoutId);
                clearTimeout(timeoutId);
                this.subscriptionResponseTimers.delete(message.id!);
                // mark the subscription as open, and index it with its SID
                const newSubscription = this.pendingSubscriptions.get(message.id!)!;
                this.subscriptions.set(payload.sid, newSubscription);
                this.pendingSubscriptions.delete(message.id!);

                // check if we were waiting to unsubscribe
                if (this.pendingUnsubscriptions.delete(newSubscription)) {
                    this.unsubscribe(newSubscription);
                }
                break;
            case 'unsubscribed':
                console.log('Server sent unsubscribed event');
                // stop sending unsubscribe commands
                if (this.unsubscriptionResponseTimers.has(message.sid!)) {
                    clearTimeout(this.unsubscriptionResponseTimers.get(message.sid!)!);
                    this.unsubscriptionResponseTimers.delete(message.sid!);
                }
                // remove subscription
                const oldSubscription = this.subscriptions.get(message.sid!)!;
                this.subscriptions.delete(message.sid!);
                // if server-forced unsubscription
                if (oldSubscription.subscribers.size > 0) {
                    for (const subscriber of oldSubscription.subscribers) {
                        // resubscribe after short delay
                        setTimeout(() => this.subscribe(oldSubscription.params, subscriber), FIXED_DELAY);
                    }
                }
                break;
            case 'error':
                // TODO: handle `error` message type
                break;
            default:
                // send channel message to appropriate subscriber(s)
                this.subscriptions.get(message.sid!)?.subscribers.forEach((subCallback) => {
                    subCallback(message);
                });
                break;
        }
    }

    /**
     * @function resubscribe
     * Utilized by the @function connect to catch up to subscription requests when connection is opened.
     */
    resubscribe = (): void => {
        const prev_subscriptions: WsSubscription[] = [
            ...this.pendingSubscriptions.values(),
            ...this.subscriptions.values(),
        ];

        this.pendingSubscriptions.clear();
        this.subscriptions.clear();

        for (const subscription of prev_subscriptions) {
            for (const subscriber of subscription.subscribers) {
                this.subscribe(subscription.params, subscriber);
            }
        }
    };

    /**
     * @function unsubscribe
     * Utilized by the callback returned in the @function connect to handle unsubscription logic.
     */
    unsubscribe = (subscription: WsSubscription): void => {
        for (const [sid, sub] of this.subscriptions) {
            if (sub === subscription) {
                this.unsubscribeWithBackoff(sid, this.nextCommandId);
                this.nextCommandId++;
                return;
            }
        }
        // still awaiting subscription confirmation
        this.pendingUnsubscriptions.add(subscription);
    };

    /**
     * @function unsubscribeWithBackoff
     * Send an unsubscribe command and resend with exponential backoff.
     */
    unsubscribeWithBackoff = (sid: number, cid: number, backoffExponent = 0): void => {
        const command: WsCommand = {
            id: cid,
            cmd: 'unsubscribe',
            params: { sids: [sid] },
        };

        if (this.conn?.readyState === WebSocket.OPEN) {
            this.conn?.send(JSON.stringify(command));
        }
        const { delay, random_ms, next_exp } = this.exponentialBackoff(backoffExponent);

        this.unsubscriptionResponseTimers.set(
            sid,
            setTimeout(() => this.unsubscribeWithBackoff(sid, cid, next_exp), delay + random_ms),
        );
    };

    /**
     * @function matchingSubscription
     * Utilized by the @function subscribe to search through open and requested subscriptions for matching params.
     */
    matchingSubscription = (params: WsSubscribeParams): { id: number; pending: boolean } | null => {
        for (const [cid, sub] of this.pendingSubscriptions) {
            if (arraysAreEqual(params.channels, sub.params.channels) && sub.params.market_id === params.market_id) {
                return { id: cid, pending: true };
            }
        }
        for (const [sid, sub] of this.subscriptions) {
            if (arraysAreEqual(params.channels, sub.params.channels) && sub.params.market_id === params.market_id) {
                return { id: sid, pending: false };
            }
        }
        return null;
    };

    /**
     * @function exponentialBackoff
     * Compute values for exponential backoff.
     */
    exponentialBackoff = (exponent: number): { delay: number; random_ms: number; next_exp: number } => {
        return {
            delay: 2 ** exponent * 1000,
            random_ms: Math.floor(Math.random() * Math.floor(1000)),
            next_exp: Math.min(exponent + 1, MAXIMUM_BACKOFF_EXPONENT),
        };
    };
}

export default WsService;

/*
    TODO:
        1. handle error messages once they're defined
*/
