import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const OnRoute: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
    useEffect(() => {
        const unlisten = history.listen(() => {
            // on route, scroll to top
            window.scrollTo(0, 0);
        });
        return (): void => unlisten();
    });

    return null;
};

export default withRouter(OnRoute);
