import React from 'react';
import theme from '../theme';

export type PaddingSize = 'none' | 'tiny' | 'small' | 'medium' | 'large';

export interface PaddingProps {
    size?: PaddingSize;
    spacing?: number;
}

const paddingMultiplier = (size: PaddingSize | undefined): number | undefined => {
    if (size === undefined) {
        return undefined;
    }

    return size === 'none' ? 0 : size === 'tiny' ? 1 : size === 'small' ? 2 : size === 'medium' ? 3 : 6;
};

const Padding = ({ size, spacing = 1 }: PaddingProps): JSX.Element => {
    return (
        <div
            style={{
                height: theme.spacing(paddingMultiplier(size) || spacing),
                width: theme.spacing(paddingMultiplier(size) || spacing),
                flexShrink: 0,
            }}
        />
    );
};

export default Padding;
