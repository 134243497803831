import { DateTime } from 'luxon';

export type PERIOD_TYPE = '1h' | '6h' | '1d' | '1w' | '1m' | '3m' | '1y' | 'all';

const PERIOD_OPTIONS: Array<PERIOD_TYPE> = ['1h', '6h', '1d', '1w', '1m', 'all'];

const getPeriodCoefficient = (unit: string): number => {
    // Gets the coefficient to be multiplied by a hour unit to calculate for the period window
    switch (unit) {
        case 'h':
            return 1;
        case 'd':
            return 24;
        case 'w':
            return 168;
        case 'm':
            return 720;
    }
    // 'year' coefficient
    // 'all' use the year coefficient if there is no data for user
    return 8760;
};

const checkPeriod = (minTimeStamp: number, period: PERIOD_TYPE): boolean => {
    // Checks whether it makes sense to offer a period option
    const now = DateTime.now();
    let temp = DateTime.fromSeconds(minTimeStamp);
    switch (period) {
        case '1h':
            temp = temp.plus({ hours: 1 });
            break;
        case '6h':
            temp = temp.plus({ hours: 6 });
            break;
        case '1d':
            temp = temp.plus({ days: 1 });
            break;
        case '1w':
            temp = temp.plus({ weeks: 1 });
            break;
        case '1m':
            temp = temp.plus({ months: 1 });
            break;
        case '3m':
            temp = temp.plus({ months: 3 });
            break;
        case '1y':
            temp = temp.plus({ years: 1 });
            break;
        default:
            return true;
    }
    return now > temp;
};

const xAxisMask = (start: DateTime, end: DateTime): string => {
    const diff = end.diff(start);
    return diff.as('hours') < 24
        ? 'h:mm a'
        : diff.as('days') < 7
        ? 'MMM d, h:mm a'
        : diff.as('months') < 7
        ? 'MMM d'
        : 'MMM y';
};

/* TODO: remove in favor of the above function */
const LEGACY_xAxisMask = (period: PERIOD_TYPE, availablePeriodOptions: Array<PERIOD_TYPE>): string =>
    period == '1h' || period == '6h' || period == '1d'
        ? 'hh:mm a'
        : period !== 'all'
        ? 'MMMM dd'
        : availablePeriodOptions.includes('1w')
        ? 'MMMM dd'
        : availablePeriodOptions.includes('1d')
        ? 'MMMM dd hh a'
        : 'hh:mm a';

const calculateStartTs = (end: number, firstTsOnData: number | undefined, period: string): number => {
    const windowSize = getPeriodCoefficient(period.charAt(1));
    if (period === 'all') {
        // if all is picked and there is data for the user we should use the first timestamp ever
        // otherwise we calculate the start timestamp normally
        return firstTsOnData ? firstTsOnData : end - windowSize * 3600;
    }
    return end - parseInt(period.charAt(0)) * windowSize * 3600;
};

const getLineSamplingPointsNumber = (period: string): number => {
    // Gets the coefficient to be multiplied by a hour unit to calculate for the period window
    switch (period) {
        case '1H':
            // One point every minute
            return 60;
        case '6H':
            // One point every 3 minutes
            return 120;
        case '1D':
            // One point every 10 minutes
            return 144;
        case '1W':
            // One point every hour
            return 168;
        case '1M':
            // One point every hour
            return 720;
        case '3M':
            // One point every 3 hours
            return 720;
    }
    // 'all' use the maximum number of points that's feasible in the UI
    return 720;
};

const getBarSamplingPointsNumber = (period: string): number => {
    // Gets the coefficient to be multiplied by a hour unit to calculate for the period window
    switch (period) {
        case '1H':
            // One bar every minute
            return 60;
        case '6H':
            // One bar every 5 minutes
            return 72;
        case '1D':
            // One bar every 20 minutes
            return 72;
        case '1W':
            // One bar every two hours
            return 84;
        case '1M':
            // One bar every day
            return 30;
    }
    // 'all' use the maximum number of bars that's feasible in the UI
    return 84;
};

export {
    checkPeriod,
    xAxisMask,
    LEGACY_xAxisMask,
    calculateStartTs,
    getLineSamplingPointsNumber,
    getBarSamplingPointsNumber,
    PERIOD_OPTIONS,
};
