import * as _ from 'lodash';
import theme from './theme';

const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

const toMoney = (val: number): string => {
    return moneyFormatter.format(val);
};

const spacingFromTheme = (val: number | string): string => {
    // Can be used to set margin, width, height and so on
    // To pass a number that is not multiple of 8px just pass as a string
    // Ex: spacingFromTheme('5px') would return '5px'
    //     spacingFromTheme(5) would return '40px'
    //     spacingFromTheme('auto') would return 'auto'
    return typeof val === 'string' ? val : theme.spacing(val);
};

const getFromTheme = (spec: string, value: string): string => {
    const options = _.get(theme, spec);
    if (options === undefined) {
        return value;
    }
    //Powerful line, get color in the palette if exists or return the input color in case is not a path
    //So getFromTheme('color', 'green') would return 'green'
    //And getFromTheme('color', 'signature.green') would return the signature.green color value from palette
    //This way we can use theme values and hardcoded values in the same way
    return _.get(options, value, value);
};

export { toMoney, getFromTheme, spacingFromTheme };
