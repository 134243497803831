import * as React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/FlagTwoTone';
import { useStores } from '../../../hooks/use-stores';
import { Task } from '../../../stores/domain/Task';
import { DataGrid, GridCellParams, GridSortItem, GridSortModel } from '@material-ui/data-grid';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '500px',
        },
        actions: {
            justifyContent: 'flex-end',
        },
    }),
);

interface QuestionTaskTableProps {
    taskType: string;
}

const flagFromPritority = (priority: number): JSX.Element => {
    if (priority == 3) {
        return <FlagIcon style={{ color: 'red' }} />;
    } else if (priority == 2) {
        return <FlagIcon style={{ color: '#BBBB00' }} />;
    } else {
        return <FlagIcon style={{ color: 'blue' }} />;
    }
};

const QuestionTaskTable = ({ taskType }: QuestionTaskTableProps): JSX.Element => {
    const sortingPreferences = JSON.parse(localStorage.getItem('kira-SortingPreferencesTasksHomepage') || '{}');

    const classes = useStyles();
    const history = useHistory();
    const { taskStore } = useStores();

    const defaultItem: GridSortItem = {
        field: 'priority',
        sort: 'desc',
    };

    const [sortingPrefs, setSortModel] = React.useState<GridSortModel>(
        sortingPreferences.sortingPrefs ? sortingPreferences.sortingPrefs : [defaultItem],
    );

    useEffect(() => {
        localStorage.setItem(
            'kira-SortingPreferencesTasksHomepage',
            JSON.stringify({
                sortingPrefs,
            }),
        );
    }, [sortingPrefs]);

    let title;
    let tasks: Task[] = [];

    const columns = [
        {
            field: 'priority',
            headerName: 'Priority',
            width: 150,
            sortable: true,
            renderCell: (params: GridCellParams) => {
                return flagFromPritority(params.value as number);
            },
        },
        {
            field: 'pipeline',
            headerName: 'Pipeline',
            width: 180,
            sortable: true,
        },
        {
            field: 'triggers',
            headerName: 'Triggers',
            width: 180,
            sortable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 180,
            sortable: true,
        },
        {
            field: 'timeCreated',
            headerName: 'Time Created',
            width: 180,
            sortable: true,
        },
        {
            field: 'reviewer',
            headerName: 'Reviewer',
            width: 180,
            sortable: true,
        },
    ];

    switch (taskType) {
        case 'gm':
            tasks = taskStore.openMarketTasks;
            title = 'Open Market Tasks';
            columns.push({
                field: 'marketId',
                headerName: 'Market ID',
                width: 180,
                sortable: true,
            });
            break;
        case 'gu':
            tasks = taskStore.openUserTasks;
            title = 'Open User Tasks';
            columns.push({
                field: 'userId',
                headerName: 'User ID',
                width: 180,
                sortable: true,
            });
            break;
        case 'um':
            tasks = taskStore.openUserMarketTasks;
            title = 'Open UserMarket Tasks';
            columns.push({
                field: 'marketId',
                headerName: 'Market ID',
                width: 180,
                sortable: true,
            });
            columns.push({
                field: 'userId',
                headerName: 'User ID',
                width: 180,
                sortable: true,
            });
            break;
        case 'uu':
            tasks = taskStore.openUserUserTasks;
            title = 'Open UserUser Tasks';
            columns.push({
                field: 'userId1',
                headerName: 'User ID 1',
                width: 180,
                sortable: true,
            });
            columns.push({
                field: 'userId2',
                headerName: 'User ID2',
                width: 180,
                sortable: true,
            });
            break;
        default:
            tasks = taskStore.open;
            columns.push({
                field: 'marketId',
                headerName: 'Market ID',
                width: 180,
                sortable: true,
            });
            columns.push({
                field: 'userId',
                headerName: 'User ID',
                width: 180,
                sortable: true,
            });
            title = 'Open Tasks';
    }

    const rows = tasks.map((entry) => {
        const progress = entry.statusText;

        return {
            id: entry.taskId,
            priority: entry.priority,
            pipeline: entry.pipelineString,
            triggers: entry.triggers?.map((trigger) => trigger.trigger_description).join(', '),
            status: progress,
            timeCreated: entry.createdTimestamp,
            reviewer: entry.reviewer,
            marketId: entry.identifiers?.market_id,
            userId: entry.identifiers?.user_id,
            userId1: entry.identifiers?.user_id_large,
            userId2: entry.identifiers?.user_id_small,
        };
    });

    return (
        <div>
            <Card className={classes.root}>
                <DataGrid
                    sortModel={sortingPrefs}
                    onSortModelChange={(model) => setSortModel(model.sortModel)}
                    columns={columns}
                    rows={rows}
                    disableSelectionOnClick={true}
                    onRowClick={(params) => {
                        history.push('/tasks/' + params.row.id);
                    }}
                />
            </Card>
        </div>
    );
};

export default observer(QuestionTaskTable);
