import { action, observable } from 'mobx';
import TransportLayer from '../transport/transport_layer';
import TaskStore from './tasks';
import UserStore from './user';
import MarketStore from './market';
import { WebSocket } from '../transport';
import TraderStore from './trader';

export class RootStore {
    transportLayer: TransportLayer;
    ws: WebSocket;
    taskStore: TaskStore;
    userStore: UserStore;
    traderStore: TraderStore;
    marketStore: MarketStore;

    @observable
    sessionExpired = false;

    constructor() {
        this.ws = new WebSocket();
        this.transportLayer = new TransportLayer(this);
        this.taskStore = new TaskStore(this);
        this.userStore = new UserStore(this);
        this.traderStore = new TraderStore(this);
        this.marketStore = new MarketStore(this, this.ws);
    }

    dispose(): void {
        this.sessionExpired = false;
    }

    @action
    setSessionExpired(val: boolean): void {
        this.sessionExpired = val;
    }
}
