import { observer } from 'mobx-react';
import { useStores } from '../hooks/use-stores';
import React, { useState } from 'react';
import { Comment } from '../models';
import { Button, Card, CardHeader, Container, Divider, MenuItem, Select, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ValueDisplay } from './index';
import { Task } from '../stores/domain/Task';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        submit: {
            margin: theme.spacing(2),
        },
    }),
);

interface ReviewSectionProps {
    task: Task;
}

const ReviewSection = observer(({ task }: ReviewSectionProps): JSX.Element => {
    const classes = useStyles();
    const { taskStore, userStore } = useStores();

    const [status, setStatus] = useState<number | undefined>();
    const [resolution, setResolution] = useState<number | undefined>();
    const [comment, setComment] = useState<string | undefined>();
    const [deadline, setDeadline] = useState<Date | undefined>();
    const reviewer = userStore.user.user_id;

    const writeComment = (e: React.FormEvent): void => {
        e.preventDefault();

        if (comment === undefined || comment === '') {
            alert('Please write a comment.');
            return;
        }

        taskStore.comment(task.taskId, comment, reviewer, () => {
            taskStore.loadTask(task.taskId);
        });

        setComment('');
    };

    const updateTaskDetails = (e: React.FormEvent): void => {
        console.log(status, deadline);
        e.preventDefault();

        if (status === undefined && resolution === undefined) {
            alert('Please update the status or resolution of the task.');
            return;
        }

        taskStore.updateTask(task.taskId, status, resolution, deadline, reviewer, () => {
            taskStore.loadTask(task.taskId);
        });

        setStatus(undefined);
        setResolution(undefined);
        setDeadline(undefined);
    };

    const comments = task.comments?.map((comment: Comment, index) => {
        return (
            <div
                key={'comment-index-' + index.toString()}
                style={{ display: 'flex', flexDirection: 'column', width: '100vw', padding: '12px' }}
            >
                <div
                    style={{
                        fontWeight: 'bold',
                    }}
                >
                    {comment.reviewer + ' at ' + moment.utc(comment.timestamp).toDate().toLocaleString() + ':'}
                </div>
                <div>{comment.content}</div>
            </div>
        );
    });

    if (!task.loaded) {
        return <div></div>;
    }

    return (
        <Container component="main">
            <Grid container>
                <Card>
                    <CardHeader title={'Task'} />
                    <Divider />
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100vw' }}>
                        <ValueDisplay ml={2} label={'Status'} value={task.statusText} />
                        <ValueDisplay ml={2} label={'Resolution'} value={task.resolutionText} />
                        <ValueDisplay ml={2} label={'Reviewer'} value={task.reviewer} />
                        <ValueDisplay ml={2} label={'Pipeline'} value={task.pipelineString} />
                        {/* <ValueDisplay ml={2} label={'Creation Time'} value={task.createdTimestamp} /> */}

                        {task.identifiers?.market_id && (
                            <ValueDisplay
                                ml={2}
                                label={'Market'}
                                value={
                                    <Link to={'/markets/' + task.identifiers.market_id}>
                                        {task.identifiers.market_id}
                                    </Link>
                                }
                            />
                        )}
                        {task.identifiers?.user_id && (
                            <ValueDisplay
                                ml={2}
                                label={'User'}
                                value={
                                    <Link to={'/users/' + task.identifiers.user_id}>{task.identifiers.user_id}</Link>
                                }
                            />
                        )}
                        {task.identifiers?.user_id_large && (
                            <ValueDisplay
                                ml={2}
                                label={'User 1'}
                                value={
                                    <Link to={'/users/' + task.identifiers.user_id_large}>
                                        {task.identifiers.user_id_large}
                                    </Link>
                                }
                            />
                        )}
                        {task.identifiers?.user_id_small && (
                            <ValueDisplay
                                ml={2}
                                label={'User 2'}
                                value={
                                    <Link to={'/users/' + task.identifiers.user_id_small}>
                                        {task.identifiers.user_id_small}
                                    </Link>
                                }
                            />
                        )}
                    </div>
                    <ValueDisplay
                        ml={2}
                        label={'Triggers'}
                        value={task.triggers
                            .map((trigger) => {
                                return trigger.trigger_description;
                            })
                            .join(', ')}
                    />
                    <div
                        style={{
                            marginTop: '8px',
                            maxHeight: '300px',
                            overflowY: 'scroll',
                            overflowX: 'clip',
                            fontFamily: 'sans-serif',
                        }}
                    >
                        {comments}
                    </div>
                    <form className={classes.form} onSubmit={writeComment}>
                        <TextField
                            variant="standard"
                            fullWidth
                            multiline
                            rowsMax={5}
                            id="comment"
                            label="Comment"
                            name="comment"
                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                            value={comment}
                            autoFocus
                            onChange={(event): void => setComment(event.target.value)}
                        />
                        <Button type="submit" variant="contained" color="primary" className={classes.submit}>
                            Submit Comment
                        </Button>
                    </form>
                    <form className={classes.form} onSubmit={updateTaskDetails}>
                        <Select
                            label="Status"
                            labelId="status"
                            id="status"
                            value={status ? status : ''}
                            onChange={(event: any): void => setStatus(event.target.value)}
                        >
                            <MenuItem value={0}>Pending</MenuItem>
                            <MenuItem value={1}>In Progress</MenuItem>
                            <MenuItem value={2}>Stall</MenuItem>
                            <MenuItem value={3}>Resolved</MenuItem>
                        </Select>
                        <Select
                            label="Resolution"
                            labelId="resolution"
                            id="resolution"
                            value={resolution ? resolution : ''}
                            onChange={(event: any): void => setResolution(event.target.value)}
                        >
                            <MenuItem value={0}>No Action</MenuItem>
                            <MenuItem value={1}>CMIR Increase</MenuItem>
                            <MenuItem value={2}>Mild Alert</MenuItem>
                            <MenuItem value={3}>Disciplinary Procedure</MenuItem>
                        </Select>
                        <Button type="submit" variant="contained" color="primary" className={classes.submit}>
                            Update Task Details
                        </Button>
                    </form>
                </Card>
            </Grid>
        </Container>
    );
});

export default ReviewSection;
