import React from 'react';
import { observer } from 'mobx-react';
import { IgrFinancialChartModule, IgrFinancialChart } from 'igniteui-react-charts';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Market, MarketStats } from '../../../stores/domain/Market';

IgrFinancialChartModule.register();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            textAlign: 'left',
        },
    }),
);

interface DataPoint {
    time: Date;
    close: number;
}

interface PriceGraphProps {
    market: Market;
}

const PriceGraph = ({ market }: PriceGraphProps) => {
    const stats = market.statsHistory;

    const classes = useStyles();

    if (!!stats) {
        const content: DataPoint[] = stats.map((stat: MarketStats) => ({
            time: new Date(stat.ts * 1000),
            close: stat.price / 100,
        }));
        return (
            <Box display={'flex'} flexDirection={'column'} style={{ width: '100%', maxHeight: '40vh' }}>
                <Typography variant={'h6'} className={classes.title}>
                    Price History (ticker)
                </Typography>
                <IgrFinancialChart
                    width="35vw"
                    height="100%"
                    dataSource={content}
                    chartType="line"
                    isToolbarVisible={false}
                    crosshairsDisplayMode="Vertical"
                    crosshairsSnapToData={false}
                    crosshairsAnnotationEnabled={false}
                    chartTitle="Price History"
                />
            </Box>
        );
    }
    return null;
};

export default observer(PriceGraph);
