import MarketStore from '../market/market';

import { action, computed, observable } from 'mobx';
import { CancelTokenSource } from 'axios';
import moment from 'moment';
import { MarketStatus } from '../../models/market';
import { HistoricalMarketStats, MarketVo } from '../../models';

export interface MarketStats {
    yes: number;
    no: number;
    spread: number;
    price: number;
    volume: number;
    openInterest: number;
    ts: number;
}

export const INITIAL_MARKET_STATS = {
    yes: 50,
    no: 50,
    spread: 100,
    price: 0,
    volume: 0,
    openInterest: 0,
    ts: Date.now() / 1000,
};

const emptyStatsHistory: Array<MarketStats> = [
    { yes: 50, no: 50, spread: 0, price: 50, volume: 0, openInterest: 0, ts: 0 },
];

export class Market {
    polling = false;
    loading = false;

    @observable
    id = '';

    @observable
    title = '';
    @observable
    category = '';
    @observable
    tags = [''];
    @observable
    createDate = '';
    @observable
    closeDate = '';
    @observable
    openDate = '';

    @observable
    tickerName = '';

    @observable
    status: MarketStatus = 'inactive';

    // prices are in CentiCentSmall = cent / 100 = dollar / 10,000
    @observable
    yesBid = 50;
    @observable
    yesAsk = 50;
    @observable
    lastPrice = 0;
    @observable
    previousRecordedVolume: number | null = null;

    @observable
    volume = 0;
    @observable
    recentVolume = 0;
    @observable
    openInterest = 0;

    @observable
    resultIsYes = false;

    // not sent over API call
    @observable
    isSaved = false;
    @observable
    statsHistory: Array<MarketStats> = emptyStatsHistory;

    // missing: volume timeseries, views / watches

    store: MarketStore | null = null;

    timer: NodeJS.Timeout | null = null;
    fetchRef: CancelTokenSource | null = null;

    constructor(store: MarketStore, vo: MarketVo | undefined = undefined) {
        this.store = store;
        if (!!vo) {
            this.updateFromVo(vo);
        }
    }

    /**
     * Update this order with information from the server
     */
    @action
    updateFromVo(vo: MarketVo): Market {
        this.id = vo.id;
        this.tickerName = vo.ticker_name;
        this.title = vo.title;
        this.category = vo.category;
        this.tags = vo.tags;
        this.createDate = vo.create_date;
        this.closeDate = vo.close_date;
        this.openDate = vo.open_date;
        this.status = vo.status;

        this.yesBid = 0 < vo.yes_bid && vo.yes_bid < 100 ? vo.yes_bid : 0;
        this.yesAsk = 0 < vo.yes_ask && vo.yes_ask < 100 ? vo.yes_ask : 100;

        if (this.volume !== 0) {
            this.previousRecordedVolume = this.volume;
        }

        this.lastPrice = vo.last_price;

        this.volume = vo.volume;
        this.recentVolume = vo.recent_volume;
        this.openInterest = vo.open_interest;

        this.resultIsYes = vo.result_is_yes;

        return this;
    }

    @computed get toOpen(): boolean {
        const now = moment().utc();
        return this.status === 'initialized' && !!this.openDate && moment(this.openDate).utc().isAfter(now);
    }

    @computed get isOpen(): boolean {
        // We still don't have views for inactive markets
        // So all non-closed status will be displayed as an active  market for now
        /*const now = moment().utc();
        if (!!this.closeDate && moment(this.closeDate).utc().isBefore(now)) {
            return false;
        }
        if (!!this.openDate && moment(this.openDate).utc().isBefore(now)) {
            return true;
        }*/

        return this.status === 'active';
    }

    @computed get isClosed(): boolean {
        return !this.isOpen;
    }

    @computed get isDetermined(): boolean {
        return this.status === 'determined' || this.isSettled;
    }

    @computed get isSettled(): boolean {
        return this.status === 'settled' || this.status === 'finalized';
    }

    @computed get yesBidInDollars(): number {
        return this.yesBid / 100;
    }

    @computed get yesAskInDollars(): number {
        return this.yesAsk / 100;
    }

    // the below functions return the *price you can buy a yes/no at* (i.e. the best offer)
    @computed get yesPriceInCents(): number {
        const price = this.yesAsk;
        if (price < 1 || price > 99) {
            return 100; // indicates no offer, default value
        }
        return price;
    }

    @computed get noPriceInCents(): number {
        const price = 100 - this.yesBid;
        if (price < 1 || price > 99) {
            return 100; // indicates no offer, default value
        }
        return price;
    }

    @computed get yesPriceInDollars(): number {
        return this.yesPriceInCents / 100;
    }

    @computed get noPriceInDollars(): number {
        return this.noPriceInCents / 100;
    }

    @computed get bestYesPriceInCents(): number {
        if (this.yesPriceInCents === 100 && this.noPriceInCents === 100) {
            // if there are no bids or offers, default to price of 50
            return 50;
        }
        return Math.min(this.yesPriceInCents, 99);
    }

    @computed get bestNoPriceInCents(): number {
        if (this.yesPriceInCents === 100 && this.noPriceInCents === 100) {
            // if there are no bids or offers, default to price of 50
            return 50;
        }
        return Math.min(this.noPriceInCents, 99);
    }

    @computed get bestYesPriceInDollars(): number {
        return this.bestYesPriceInCents / 100;
    }

    @computed get bestNoPriceInDollars(): number {
        return this.bestNoPriceInCents / 100;
    }

    @computed get viewsInK(): number {
        return 3;
    }

    @computed get volumeDifference(): number | null {
        // Records the change in volume since the market was last
        // polled.
        if (this.previousRecordedVolume !== null) {
            return this.volume - this.previousRecordedVolume;
        }

        return null;
    }

    @computed get marketDataInDollars(): Array<MarketStats> {
        return this.statsHistory.map((historicalPrice) => ({
            yes: historicalPrice.yes / 100,
            no: historicalPrice.no / 100,
            price: historicalPrice.price / 100,
            spread: historicalPrice.spread / 100,
            volume: historicalPrice.volume,
            openInterest: historicalPrice.openInterest,
            ts: historicalPrice.ts,
        }));
    }

    @action
    updateStatsHistoryFromVo(prices: Array<HistoricalMarketStats>): void {
        if (!prices) {
            this.statsHistory = emptyStatsHistory;
            return;
        }

        if (
            // Optimization to avoid unnecessary re-renders because of useless content replacement
            // Without this condition user hover is lost in the polling
            this.statsHistory.length === 0 ||
            this.statsHistory[this.statsHistory.length - 1].ts !== prices[prices.length - 1].ts
        ) {
            this.statsHistory = prices.map((vo) => {
                const histStats: MarketStats = {
                    yes: vo.yes_ask,
                    no: 100 - vo.yes_bid,
                    spread: vo.yes_ask - vo.yes_bid,
                    price: vo.price,
                    volume: vo.volume,
                    openInterest: vo.open_interest,
                    ts: vo.ts,
                };
                return histStats;
            });
        }
    }

    @action
    appendStatsHistoryFromVo(prices: Array<HistoricalMarketStats>): void {
        this.statsHistory = this.statsHistory.concat(
            prices.map((vo) => {
                const histStats: MarketStats = {
                    yes: vo.yes_ask,
                    no: 100 - vo.yes_bid,
                    spread: vo.yes_ask - vo.yes_bid,
                    price: vo.price,
                    volume: vo.volume,
                    openInterest: vo.open_interest,
                    ts: vo.ts,
                };
                return histStats;
            }),
        );

        console.log(this.statsHistory);
    }

    startPolling(intervalMS: number): void {
        if (!this.polling) {
            this.polling = true;

            this.store?.loadMarketStats(this.id);

            this.timer = setInterval(
                action(() => {
                    this.loading = true;
                    this.store?.loadMarketStats(this.id);
                }),
                intervalMS,
            );
        }
    }

    stopPolling(): void {
        if (this.loading) {
            if (this.fetchRef !== null) {
                this.fetchRef.cancel('UserDashboard fetch request aborted!');
                this.fetchRef = null;
            }
            this.loading = false;
        }
        if (this.polling) {
            if (this.timer !== null) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.polling = false;
        }
    }

    dispose(): void {
        // clean up the observer
        this.stopPolling();
    }
}
