import React from 'react';
import { DashboardComponent } from '../../../components';
import Typography from '@material-ui/core/Typography';
import { IgrFinancialChart } from 'igniteui-react-charts';
import { Box, MenuItem, TextField } from '@material-ui/core';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { KiraStats } from '../../../models';

const METRIC_OPTIONS: any = {
    position_cost: { label: 'Position Cost', factor: 1 },
    dvar: { label: 'DVAR', factor: 1 },
    uvar: { label: 'UVAR', factor: 1 },
    volume: { label: 'Volume', factor: 1 },
    open_interest: { label: 'Open Interest', factor: 1 },
};

interface StatsGraphProps {
    userStats: Array<KiraStats>;
}

const StatsGraph = ({ userStats }: StatsGraphProps): JSX.Element => {
    const [metric, setMetric] = useState<string>('volume');

    let content: any[];
    const identifiedStat = userStats.filter((stat) => {
        return stat.stat_name == metric;
    });
    if (identifiedStat == undefined || identifiedStat.length == 0) {
        content = [];
    } else {
        content = identifiedStat[0].measurements.map((measurement) => ({
            time: measurement.timestamp,
            close: measurement.value,
        }));
    }

    return (
        <DashboardComponent mt={1} width={'100%'} display={'flex'} flexDirection={'column'} minHeight="40vh">
            <Box mt={1} mb={1} display={'flex'} alignItems={'center'}>
                <Typography variant={'h6'}>Stats</Typography>
                <Box ml={2}>
                    <TextField
                        select
                        label={'Metric'}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        value={metric}
                        onChange={(e) => setMetric(e.target.value as string)}
                    >
                        {Object.keys(METRIC_OPTIONS).map((option) => (
                            <MenuItem key={`select-user-metric-${option}`} value={option}>
                                {METRIC_OPTIONS[option].label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
            <Box mt={2}>
                <IgrFinancialChart
                    width="100%"
                    height="100%"
                    dataSource={content}
                    chartType="line"
                    isToolbarVisible={true}
                    crosshairsDisplayMode="Vertical"
                    crosshairsSnapToData={false}
                    crosshairsAnnotationEnabled={false}
                    chartTitle={METRIC_OPTIONS[metric].label}
                />
            </Box>
        </DashboardComponent>
    );
};

export default observer(StatsGraph);
