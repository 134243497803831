import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, TableBody, TableCell, TableContainer, TableHead, Table } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import { Order } from '../../../stores/domain/Order';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            height: '80%',
            width: '1px',
            backgroundColor: 'black',
        },
        title: {
            textAlign: 'left',
        },
    }),
);

interface OrderReconstruction {
    orders: Order[];
    userId: string;
}

const OrderReconstructionComponent = ({ orders, userId }: OrderReconstruction): JSX.Element => {
    const classes = useStyles();

    let content;
    if (orders !== undefined) {
        content = orders.map((entry, index) => (
            <TableRow key={index}>
                <TableCell>{new Date(Date.parse(entry.createTs)).toLocaleString()}</TableCell>
                <TableCell>{entry.marketId}</TableCell>
                <TableCell>{entry.placeCount}</TableCell>
                <TableCell>{entry.remainingCount}</TableCell>
                <TableCell>{entry.decreaseCount}</TableCell>
                <TableCell>{entry.makerFillCount}</TableCell>
                <TableCell>{entry.isYes ? 'Yes' : 'No'}</TableCell>
                <TableCell>{entry.price / 100}</TableCell>
                <TableCell>{entry.lastUpdateOp}</TableCell>
            </TableRow>
        ));
    } else {
        content = <></>;
    }

    return (
        <Box display={'flex'} flexDirection={'column'} style={{ width: '100%', maxHeight: '40vh' }}>
            <Typography variant={'h6'} className={classes.title}>
                Order Reconstruction
            </Typography>
            <Box mt={2}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Market</TableCell>
                                <TableCell>Placed Quantity</TableCell>
                                <TableCell>Remaining Quantity</TableCell>
                                <TableCell>User Cancel Quantity</TableCell>
                                <TableCell>Maker Fill Count</TableCell>
                                <TableCell>Direction</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Last Update Operation</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: 'auto' }}>{content}</TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default observer(OrderReconstructionComponent);
