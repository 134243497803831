import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import theme from '../theme';
import Box, { BoxProps } from '../Box/Box';

const useStyles = createUseStyles({
    root: {
        background: theme.palette.common.fullWhite,
        border: `1px solid ${theme.palette.text.blackLite}`,
        borderRadius: '4px',
        alignItems: 'flex-start',
        display: 'inline-block',
        fontFamily: theme.typography.fontFamily,
    },
});

export type CardProps = BoxProps;

const Card = forwardRef(
    ({ className, children, ...otherProps }: CardProps, ref: React.Ref<HTMLDivElement>): JSX.Element => {
        const classes = useStyles();

        return (
            <Box ref={ref} className={clsx(classes.root, className)} {...otherProps}>
                {children}
            </Box>
        );
    },
);

Card.displayName = 'Card';
export default Card;
