import * as React from 'react';
import { observer } from 'mobx-react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';
import { OrderVo } from '../models/order';

interface OrderLogProps {
    orders: OrderVo[];
    height: string;
}

function OrderLog(props: OrderLogProps): JSX.Element {
    if (!props.orders) {
        return <Typography>No orders</Typography>;
    }
    const content = props.orders
        .sort((a, b) => Date.parse(b.create_ts) - Date.parse(a.create_ts))
        .map((entry) => (
            <TableRow key={entry.order_id}>
                <TableCell align="right">
                    <Link to={'/market/' + entry.market_id}>{entry.market_id}</Link>
                </TableCell>
                <TableCell align="right">
                    <Link to={'/trader/' + entry.user_id}>{entry.user_id}</Link>
                </TableCell>
                <TableCell align="right">{new Date(Date.parse(entry.create_ts)).toLocaleString()}</TableCell>
                <TableCell align="right">{entry.is_yes ? 'Yes' : 'No'}</TableCell>
                <TableCell align="right">{entry.price / 100}</TableCell>
                <TableCell align="right">{entry.place_count + entry.taker_fill_count}</TableCell>
                <TableCell align="right">{entry.taker_fill_count}</TableCell>
                <TableCell align="right">{entry.maker_fill_count}</TableCell>
                <TableCell align="right">{entry.decrease_count}</TableCell>
                <TableCell align="right">{entry.fcc_cancel_count + entry.close_cancel_count}</TableCell>
            </TableRow>
        ));
    return (
        <TableContainer style={{ maxHeight: props.height }}>
            <Table stickyHeader aria-label="trade reconstruction table">
                <TableHead>
                    <TableRow>
                        <TableCell align="right">Market</TableCell>
                        <TableCell align="right">User</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">Yes/No</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Initial Place Count</TableCell>
                        <TableCell align="right">Taker Match</TableCell>
                        <TableCell align="right">Maker Match</TableCell>
                        <TableCell align="right">User Cancelled</TableCell>
                        <TableCell align="right">System Cancelled</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{content}</TableBody>
            </Table>
        </TableContainer>
    );
}

export default observer(OrderLog);
