import { DateTime } from 'luxon';
import config from './config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setWithExpiration = (key: string, value: any, isLocalStorage: boolean): void => {
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = JSON.stringify({
        value: value,
        expiry: DateTime.now().toSeconds() + config.sessionLength,
    });

    if (isLocalStorage) {
        localStorage.setItem(key, item);
    } else {
        sessionStorage.setItem(key, item);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setLocalStorageWithExpiration = (key: string, value: any): void => {
    setWithExpiration(key, value, true);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setSessionStorageWithExpiration = (key: string, value: any): void => {
    setWithExpiration(key, value, false);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getWithExpiration = (key: string, isLocalStorage: boolean): any => {
    const itemStr = isLocalStorage ? localStorage.getItem(key) : sessionStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!!itemStr) {
        try {
            const item = JSON.parse(itemStr);
            // compare the expiry time of the item with the current time
            if (DateTime.now().toSeconds() > item.expiry) {
                // If the item is expired, delete the item from storage
                // and return null
                if (isLocalStorage) {
                    localStorage.removeItem(key);
                } else {
                    sessionStorage.removeItem(key);
                }
                return null;
            }
            return item.value;
        } catch {
            return null;
        }
    }

    return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLocalStorageWithExpiration = (key: string): any => {
    return getWithExpiration(key, true);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSessionStorageWithExpiration = (key: string): any => {
    return getWithExpiration(key, false);
};

export {
    getLocalStorageWithExpiration,
    setLocalStorageWithExpiration,
    setSessionStorageWithExpiration,
    getSessionStorageWithExpiration,
};
