import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, TableBody, TableCell, TableContainer, TableHead, Table } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Trade } from '../../../stores/domain/Trade';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        divider: {
            height: '80%',
            width: '1px',
            backgroundColor: 'black',
        },
        title: {
            textAlign: 'left',
        },
    }),
);

interface TradeReconstruction {
    trades: Trade[];
    userId: string;
}

function resolveDirection(userId: string, trade: Trade): string {
    if (trade.is_yes_taker) {
        if (userId === trade.taker_user_id) {
            return 'Yes';
        } else {
            return 'No';
        }
    } else {
        if (userId === trade.taker_user_id) {
            return 'No';
        } else {
            return 'Yes';
        }
    }
}

const TradeReconstructionComponent = ({ trades, userId }: TradeReconstruction): JSX.Element => {
    const classes = useStyles();

    let content;
    if (trades !== undefined) {
        content = trades.map((entry, index) => (
            <TableRow key={index}>
                <TableCell>{new Date(Date.parse(entry.date)).toLocaleString()}</TableCell>
                <TableCell>{entry.marketId}</TableCell>
                <TableCell>{userId === entry.maker_user_id ? 'Maker' : 'Taker'}</TableCell>
                <TableCell>{resolveDirection(userId, entry)}</TableCell>
                <TableCell>
                    {userId === entry.maker_user_id ? (
                        <Link to={`/users/${entry.taker_user_id}`}>{`#${entry.taker_user_id.substr(0, 5)}`}</Link>
                    ) : (
                        <Link to={`/users/${entry.maker_user_id}`}>{`#${entry.maker_user_id.substr(0, 5)}`}</Link>
                    )}
                </TableCell>
                <TableCell>{entry.count}</TableCell>
                <TableCell>{entry.price / 100}</TableCell>
            </TableRow>
        ));
    } else {
        content = <></>;
    }

    return (
        <Box display={'flex'} flexDirection={'column'} style={{ width: '100%', maxHeight: '40vh' }}>
            <Typography variant={'h6'} className={classes.title}>
                Trade Reconstruction
            </Typography>
            <Box mt={2}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Market</TableCell>
                                <TableCell>Side</TableCell>
                                <TableCell>Trade Direction</TableCell>
                                <TableCell>Counterparty</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: 'auto' }}>{content}</TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default observer(TradeReconstructionComponent);
