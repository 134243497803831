import React from 'react';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';

import ValueDisplay from '../../../components/ValueDisplay';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { UserVo } from '../../../models';

interface SummaryProps {
    user: UserVo;
}

const Summary = ({ user }: SummaryProps): JSX.Element => {
    return (
        <Box ml={2}>
            <Typography variant={'h5'}>{user.first_name + ' ' + user.last_name}</Typography>
            <Box mt={1} mb={1} display={'flex'}>
                <ValueDisplay label={'Status'} value={user.is_closed ? 'Inactive' : 'Active'} />
                <ValueDisplay
                    label={'Risk Limit'}
                    value={!!user.per_market_risk_limit ? user.per_market_risk_limit : '$25,000'}
                />
                <ValueDisplay ml={4} label={'Created on'} value={moment.utc(user.create_date).local().format('lll')} />
            </Box>
        </Box>
    );
};

export default observer(Summary);
