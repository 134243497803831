import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        width: 60,
        height: 60,
    },
    link: {
        marginRight: 20,
        color: theme.palette.text.primary,
        fontWeight: 600,
    },
}));

interface NavbarProps {
    className?: string;
}

const NavBar = ({ className, ...rest }: NavbarProps): JSX.Element => {
    const classes = useStyles();

    return (
        <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
            <Toolbar>
                <Typography className={classes.link} component={RouterLink} to={'/'}>
                    Kira
                </Typography>
                <Typography className={classes.link} component={RouterLink} to={'/audit'}>
                    Audit
                </Typography>
                <Typography className={classes.link} component={RouterLink} to={'/realtime_pane'}>
                    Realtime
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default observer(NavBar);
