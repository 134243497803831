import axios, { CancelTokenSource, AxiosError, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import config from '../utils/config';
import {
    GetAuditResponse,
    GetKiraStatsResponse,
    GetMarketResponse,
    GetMarketStatsHistoryResponse,
    GetTasksResponse,
    GetOrderBookResponse,
    GetOrdersResponse,
    GetProfileResponse,
    GetTradesResponse,
    LoginRequest,
    LoginResponse,
    MarketsResponse,
    UpdateTaskRequest,
    CommentRequest,
} from '../models/reqres';
import {
    TaskVo,
    ServerError,
    ApiErrorType,
    MarketVo,
    HistoricalMarketStats,
    OrderBookEntryVoNew,
    UserVo,
    TradeVo,
    AuditEntry,
    KiraStats,
} from '../models';
import {
    CSRF_COOKIE_NAME,
    CSRF_HEADER_NAME,
    CSRF_LOCAL_STORAGE_KEY,
    USER_ID_LOCAL_STORAGE_KEY,
} from '../constants/session';

import { RootStore } from '../stores/root';
import { OrderVo } from '../models/order';
import { getLocalStorageWithExpiration, setLocalStorageWithExpiration } from '../utils/storage';

const noop = (): null => null;

export default class TransportLayer {
    store: RootStore;
    exchangeApi: AxiosInstance;
    ktssApi: AxiosInstance;

    constructor(rootStore: RootStore) {
        this.store = rootStore;

        // set up the exchange api
        this.exchangeApi = axios.create({
            baseURL: `${config.exchangeApiUrl}/v1`,
            withCredentials: true,
            xsrfHeaderName: CSRF_HEADER_NAME,
            xsrfCookieName: CSRF_COOKIE_NAME,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            timeout: config.requestTimeoutMs,
        });
        this.exchangeApi.interceptors.request.use((config) => this.csrfInterceptor(config));
        this.exchangeApi.interceptors.response.use(
            (response) => {
                // Custom handling of the response in case of success
                if (!!response?.data?.error) {
                    const axiosError: AxiosError<AxiosResponse> = {
                        config: response.config,
                        response,
                        isAxiosError: false,
                        message: 'Business error',
                        name: 'ApiBusinessError',
                        toJSON: () => response.data,
                    };
                    return Promise.reject(axiosError);
                }
                return response;
            },
            (error) => {
                if (!axios.isCancel(error)) {
                    this.parseError(error);
                }
                return Promise.reject(error);
            },
        );

        // set up the ktss api
        this.ktssApi = axios.create({
            baseURL: `${config.ktssApiUrl}/ktss`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        this.ktssApi.interceptors.request.use((config) => this.csrfInterceptor(config));
        this.ktssApi.interceptors.response.use(
            (response) => {
                // Custom handling of the response in case of success
                if (!!response?.data?.error) {
                    const axiosError: AxiosError<AxiosResponse> = {
                        config: response.config,
                        response,
                        isAxiosError: false,
                        message: 'Business error',
                        name: 'ApiBusinessError',
                        toJSON: () => response.data,
                    };
                    return Promise.reject(axiosError);
                }
                return response;
            },
            (error) => {
                if (!axios.isCancel(error)) {
                    this.handleError(this.parseError(error));
                }
                return Promise.reject(error);
            },
        );
    }

    csrfInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        let csrfToken = getLocalStorageWithExpiration(CSRF_LOCAL_STORAGE_KEY);

        const method = config.method?.toLowerCase();
        if (typeof method === 'string') {
            const isWriteMethod = ['post', 'put', 'patch', 'delete'].includes(method);
            if (isWriteMethod) {
                if (!csrfToken) {
                    csrfToken = await this.getPreSession();
                    setLocalStorageWithExpiration(CSRF_LOCAL_STORAGE_KEY, csrfToken);
                }
            }
        }
        if (!!csrfToken) {
            config.headers[CSRF_HEADER_NAME] = csrfToken;
        }

        return config;
    };

    handleError(serverError: ServerError): void {
        const userStore = this.store.userStore;

        // State changes here
        if (serverError.unauthorized || serverError.forbidden) {
            // These means the user session is corrupted
            // One of the headers / cookies could have been erased / expired
            // Nothing we can do but cleaning all the local stored state (localStorage and sessionStorage)

            localStorage.removeItem(USER_ID_LOCAL_STORAGE_KEY);
            localStorage.removeItem(CSRF_LOCAL_STORAGE_KEY);
            userStore.clearUserId();
        }
    }

    parseError(error: AxiosError): ServerError {
        const wrapper: ServerError = {
            unknown: false,
            invalid: false,
            notFound: false,
            unauthorized: false,
            forbidden: false,
            unavailable: false,
            internal: false,
            maintenance: false,
            error: {
                message: '',
                code: '',
                service: '',
            },
        };

        if (error.response) {
            const code = error.response.status;

            if (code === 500) {
                wrapper.internal = true;
            } else if (code > 500) {
                wrapper.unavailable = true;
            } else if (code === 404) {
                wrapper.notFound = true;
            } else if (code === 401) {
                wrapper.unauthorized = true;
            } else if (code === 403) {
                wrapper.forbidden = true;
            } else if (code === 400) {
                wrapper.invalid = true;
            } else {
                wrapper.unknown = true;
            }
            if (!!error?.response?.data) {
                const data = error.response.data;
                if ((data as ApiErrorType) && !!data.error) {
                    wrapper.error = data.error;
                }
            }
        } else if (error.request) {
            wrapper.unavailable = true;
        } else {
            wrapper.unknown = true;
        }

        return wrapper;
    }

    login(
        payload: LoginRequest,
        onSuc: (resp: LoginResponse) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.exchangeApi
            .post<LoginResponse>('/log_in', payload, { cancelToken: cancelRef.token })
            .then((response) => {
                onSuc(response.data);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getGlobalMarketStats(
        marketId: string,
        window_duration: string,
        onSuc: (stats: Array<KiraStats>) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.ktssApi
            .get<GetKiraStatsResponse>(`/gm_stats/${marketId}`, {
                cancelToken: cancelRef.token,
                params: {
                    window_duration: window_duration,
                },
            })
            .then((response) => {
                onSuc(response.data.stats);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getGlobalUserStats(
        userId: string,
        window_duration: string,
        onSuc: (stats: Array<KiraStats>) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.ktssApi
            .get<GetKiraStatsResponse>(`/gu_stats/${userId}`, {
                cancelToken: cancelRef.token,
                params: {
                    window_duration: window_duration,
                },
            })
            .then((response) => {
                onSuc(response.data.stats);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getUserMarketStats(
        userId: string,
        marketId: string,
        window_duration: string,
        onSuc: (stats: Array<KiraStats>) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.ktssApi
            .get<GetKiraStatsResponse>(`/um_stats/${userId}/${marketId}`, {
                cancelToken: cancelRef.token,
                params: {
                    window_duration: window_duration,
                },
            })
            .then((response) => {
                onSuc(response.data.stats);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getUserUserStats(
        userId1: string,
        userId2: string,
        window_duration: string,
        onSuc: (stats: Array<KiraStats>) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.ktssApi
            .get<GetKiraStatsResponse>(`/uu_stats/${userId1}/${userId2}`, {
                cancelToken: cancelRef.token,
                params: {
                    window_duration: window_duration,
                },
            })
            .then((response) => {
                onSuc(response.data.stats);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getUserTasks(
        userId: string,
        onSuc: (tasks: TaskVo[]) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.ktssApi
            .get<GetTasksResponse>(`/gu_tasks/${userId}`, { cancelToken: cancelRef.token })
            .then((response) => {
                onSuc(response.data.tasks);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getTask(
        taskId: string,
        onSuc: (tasks: TaskVo) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.ktssApi
            .get<TaskVo>(`/tasks/${taskId}`, { cancelToken: cancelRef.token })
            .then((response) => {
                onSuc(response.data);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    updateTask(
        taskId: string,
        payload: UpdateTaskRequest,
        onSuc: () => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.ktssApi
            .post(`/tasks/${taskId}`, payload, { cancelToken: cancelRef.token })
            .then(() => {
                onSuc();
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    comment(
        taskId: string,
        payload: CommentRequest,
        onSuc: () => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.ktssApi
            .post(`/tasks/${taskId}/comment`, payload, { cancelToken: cancelRef.token })
            .then(() => {
                onSuc();
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getProfile(
        userId: string,
        onSuc: (user: UserVo) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.exchangeApi
            .get<GetProfileResponse>(`/users/${userId}`, { cancelToken: cancelRef.token })
            .then((response) => {
                onSuc(response.data.user);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(onFinally);

        return cancelRef;
    }

    adminGetUser(
        userId: string,
        onSuc: (user: UserVo) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.exchangeApi
            .get<GetProfileResponse>(`/admin/users/${userId}/profile`, { cancelToken: cancelRef.token })
            .then((response) => {
                onSuc(response.data.user);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(onFinally);

        return cancelRef;
    }

    getMarketTrades(
        marketId: string,
        onSuc: (trades: Array<TradeVo>) => void,
        onErr: (err: ServerError) => void = noop,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.exchangeApi
            .get<GetTradesResponse>(`/admin/trades?market_id=${marketId}`, { cancelToken: cancelRef.token })
            .then((response) => {
                onSuc(response.data.trades || []);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getUserTrades(
        userId: string,
        onSuc: (trades: Array<TradeVo>) => void,
        onErr: (err: ServerError) => void = noop,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.exchangeApi
            .get<GetTradesResponse>(`/admin/trades?user_id=${userId}`, { cancelToken: cancelRef.token })
            .then((response) => {
                onSuc(response.data.trades || []);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getUserOrders(
        userId: string,
        onSuc: (orders: Array<OrderVo>) => void,
        onErr: (err: ServerError) => void = noop,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.exchangeApi
            .get<GetOrdersResponse>(`/admin/orders?user_id=${userId}`, { cancelToken: cancelRef.token })
            .then((response) => {
                onSuc(response.data.orders || []);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getOpenTasks(
        onSuc: (tasks: TaskVo[]) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.ktssApi
            .get<GetTasksResponse>(`open_tasks`, {
                cancelToken: cancelRef.token,
            })
            .then((response) => {
                onSuc(response.data.tasks);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getMarkets(
        onSuc: (markets: Array<MarketVo>) => void,
        onErr: (err: ServerError) => void,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.exchangeApi
            .get<MarketsResponse>('/markets/', { cancelToken: cancelRef.token })
            .then((response) => {
                let markets: Array<MarketVo> = [];
                if (!!response.data.markets) {
                    markets = response.data.markets;
                }
                onSuc(markets);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(onFinally);

        return cancelRef;
    }

    getMarket(
        marketId: string,
        onSuc: (marketVo: MarketVo) => void,
        onErr: (err: ServerError) => void = noop,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.exchangeApi
            .get<GetMarketResponse>(`/markets/${marketId}`, { cancelToken: cancelRef.token })
            .then((response) => {
                onSuc(response.data.market);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getMarketPriceHistory(
        marketId: string,
        startTs = 0,
        onSuc: (prices: HistoricalMarketStats[]) => void,
        onErr: (err: ServerError) => void = noop,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.exchangeApi
            .get<GetMarketStatsHistoryResponse>(`/markets/${marketId}/stats_history`, {
                params: {
                    last_seen_ts: startTs | 0, // Convert to int,
                },
                cancelToken: cancelRef.token,
            })
            .then((response) => {
                onSuc(response.data.market_stats_points);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    getMarketAudit(
        marketId: string,
        onSuc: (accountHistory: Array<AuditEntry>) => void,
        onErr: (err: ServerError) => void = noop,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        // TODO: Fix this endpoint. The audit endpoints have changed and this request needs
        // to be updated.
        // this.exchangeApi
        //     .get<GetAuditResponse>(`/admin/audit/market/${marketId}`, { cancelToken: cancelRef.token })
        //     .then((response) => {
        //         onSuc(response.data.entries || []);
        //     })
        //     .catch((error) => {
        //         if (!axios.isCancel(error)) {
        //             onErr(this.parseError(error));
        //         }
        //     })
        //     .finally(() => {
        //         onFinally();
        //     });

        return cancelRef;
    }

    getOrderBook(
        marketId: string,
        onSuc: (account: OrderBookEntryVoNew) => void,
        onErr: (err: ServerError) => void = noop,
        onFinally: () => void = noop,
    ): CancelTokenSource {
        const cancelRef = axios.CancelToken.source();

        this.exchangeApi
            .get<GetOrderBookResponse>(`/markets/${marketId}/order_book`, {
                cancelToken: cancelRef.token,
            })
            .then((response) => {
                onSuc(response.data.order_book || {});
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    onErr(this.parseError(error));
                }
            })
            .finally(() => {
                onFinally();
            });

        return cancelRef;
    }

    async getPreSession(): Promise<string | null> {
        const cancelRef = axios.CancelToken.source();

        try {
            const response = await this.exchangeApi.get<{}>(`/pre_session`, { cancelToken: cancelRef.token });
            const csrfToken = response.headers[CSRF_HEADER_NAME];
            if (!!csrfToken) {
                return csrfToken;
            }
        } catch (error) {
            console.error('Error fetching pre-session token', error);
        }
        return null;
    }
}
