import React from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DashboardComponent } from '../../components';
import { Summary, StatsGraph, CurrentTasks, TradeReconstruction, KycBox, OrderReconstruction } from './components';
import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';
import { UserVo } from '../../models';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            paddingBottom: theme.spacing(6),
            minHeight: '78vh',
        },
        dashboardWrap: {
            maxWidth: '75vw',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        submit: {
            margin: theme.spacing(2),
        },
    }),
);

interface SpacedGridProps {
    user: UserVo;
}

const SpacedGrid = observer(({ user }: SpacedGridProps): JSX.Element => {
    const { traderStore, taskStore } = useStores();
    const trades = traderStore.trades[user.user_id];
    const orders = traderStore.orders[user.user_id];

    useEffect(() => {
        taskStore.loadUserTasks(user.user_id);
        traderStore.loadTraderStats(user.user_id);
    }, [user]);

    return (
        <Box p={4}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Summary user={user} />
                </Grid>
                <Grid container item xs={6}>
                    <Grid style={{ display: 'flex' }} item xs={12}>
                        <DashboardComponent
                            width={'100%'}
                            mt={1}
                            display={'flex'}
                            flexDirection={'column'}
                            minHeight="30vh"
                        >
                            <CurrentTasks userId={user.user_id} open={true} title={'Current Tasks'} />
                        </DashboardComponent>
                    </Grid>
                    <Grid style={{ display: 'flex' }} item xs={12}>
                        <DashboardComponent
                            width={'100%'}
                            mt={1}
                            display={'flex'}
                            flexDirection={'column'}
                            minHeight="30vh"
                        >
                            <CurrentTasks userId={user.user_id} open={false} title={'Past Compliance History'} />
                        </DashboardComponent>
                    </Grid>
                    <Grid style={{ display: 'flex' }} item xs={12}>
                        <DashboardComponent width={'100%'} minHeight={'20vh'}>
                            <KycBox user={user}></KycBox>
                        </DashboardComponent>
                    </Grid>
                </Grid>
                <Grid container item xs={6}>
                    <Grid style={{ display: 'flex' }} item xs={12}>
                        <DashboardComponent width={'100%'} minHeight="30vh">
                            <StatsGraph userStats={traderStore.userStats == undefined ? [] : traderStore.userStats} />
                        </DashboardComponent>
                    </Grid>
                    <Grid style={{ display: 'flex', flexDirection: 'column' }} item xs={12}>
                        <DashboardComponent
                            width={'100%'}
                            mt={1}
                            display={'flex'}
                            flexDirection={'column'}
                            minHeight="40vh"
                        >
                            <TradeReconstruction trades={trades} userId={user.user_id} />
                        </DashboardComponent>
                        <DashboardComponent
                            width={'100%'}
                            mt={1}
                            display={'flex'}
                            flexDirection={'column'}
                            minHeight="40vh"
                        >
                            <OrderReconstruction orders={orders} userId={user.user_id} />
                        </DashboardComponent>
                    </Grid>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    <Typography>Audit Log Panel</Typography>*/}
                {/*    /!*<AuditLogPanel logs={getStore().logs} />*!/*/}
                {/*</Grid>*/}
            </Grid>
        </Box>
    );
});

const UserDashboard = observer(() => {
    const { userId } = useParams<{ userId: string }>();

    const { traderStore } = useStores();
    const user = traderStore.traders[userId];
    const classes = useStyles();

    useEffect(() => {
        if (!!userId) {
            traderStore.loadUser(userId);
        }
    }, [userId]);

    return <div className={classes.dashboardWrap}>{!!user ? <SpacedGrid user={user} /> : <></>}</div>;
});

export default UserDashboard;
