import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { Button, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Page } from '../../components';
import HeaderLinkCard from './components/HeaderLinkCard';
import AnalystTasks from './components/AnalystTasks';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        minHeight: '78vh',
    },
    menu: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(3),
    },
    activeLink: {
        color: '#3BC89B !important',
    },
    link: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
        },
    },
}));

const Tasks = observer(() => {
    const classes = useStyles();
    const { taskStore, marketStore } = useStores();

    const [taskType, setTaskType] = React.useState('');

    useEffect(() => {
        taskStore.loadOpenTasks(false);
        marketStore.load();

        return (): void => {
            taskStore.stopPolling();
            marketStore.stopPolling();
        };
    }, [marketStore, taskStore]);

    const downloadReport = (e: React.FormEvent): void => {
        e.preventDefault();

        let csvContent = 'data:text/csv;charset=utf-8,';

        csvContent += [
            'Task ID',
            'Type',
            'Triggers',
            'Date Triggered',
            'Priority',
            'Status',
            'Resolution',
            'Assigned To',
            'Comments',
            'Trader ID',
            'Market ID',
            'User 1 ID (Collusion only)',
            'User 2 ID (Collusion only)',
        ].join(',');
        csvContent += '\r\n';

        taskStore.open.forEach((task) => {
            const trigger_string = task.triggers
                .map((trigger) => {
                    return trigger.trigger_description;
                })
                .join(', ');
            const trigger_dates = task.triggers
                .map((trigger) => {
                    return trigger.timestamp;
                })
                .join(', ');
            const comment_string = task.comments
                .map((comment) => {
                    return `[${comment.content}]`;
                })
                .join(', ');
            csvContent += [
                task.taskId,
                task.pipelineString,
                trigger_string,
                trigger_dates,
                task.priority,
                task.statusText,
                task.resolutionText,
                task.reviewer,
                comment_string,
                task.identifiers?.user_id,
                task.identifiers?.market_id,
                task.identifiers?.user_id_small,
                task.identifiers?.user_id_large,
            ];
            csvContent += '\r\n';
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'daily_data.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <Page title={'My Tasks'} className={classes.root}>
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <HeaderLinkCard
                            name={'Market Tasks'}
                            count={taskStore.openMarketTasks.length}
                            onClick={(): void => {
                                setTaskType('gm');
                            }}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <HeaderLinkCard
                            name={'User Tasks'}
                            count={taskStore.openUserTasks.length}
                            onClick={(): void => {
                                setTaskType('gu');
                            }}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <HeaderLinkCard
                            name={'UserMarket Tasks'}
                            count={taskStore.openUserMarketTasks.length}
                            onClick={(): void => {
                                setTaskType('um');
                            }}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <HeaderLinkCard
                            name={'UserUser Tasks'}
                            count={taskStore.openUserUserTasks.length}
                            onClick={(): void => {
                                setTaskType('uu');
                            }}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} xl={9} xs={12}>
                        <AnalystTasks taskType={taskType} />
                    </Grid>
                    <Button fullWidth variant="contained" color="primary" onClick={downloadReport}>
                        Download Daily Report
                    </Button>
                </Grid>
            </Container>
        </Page>
    );
});

export default Tasks;
