/*
  Checks if two arrays have the same elements, ignoring the order or not
 */

export const arraysAreEqual = (a: Array<unknown>, b: Array<unknown>, ignoreOrder = true): boolean => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    let ca = a,
        cb = b;
    if (ignoreOrder) {
        ca = [...a].sort();
        cb = [...b].sort();
    }
    for (let i = 0; i < ca.length; ++i) {
        if (ca[i] !== cb[i]) return false;
    }
    return true;
};
