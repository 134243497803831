import { action, computed, observable, extendObservable } from 'mobx';
import { CancelTokenSource } from 'axios';
import { TransportLayer } from '../../transport';
import { RootStore } from '../root';
import { TasksMap } from '../../models';
import { Task, Pipeline } from '../domain/Task';
import config from '../../utils/config';

const noop = (): null => null;

export default class TaskStore {
    polling = false;
    @observable loading = false;
    timer: NodeJS.Timeout | null = null;
    fetchRef: CancelTokenSource | null = null;

    @observable tasks: TasksMap = {};

    root: RootStore;

    constructor(rootStore: RootStore) {
        this.root = rootStore;
    }

    get transportLayer(): TransportLayer {
        return this.root.transportLayer;
    }

    @action loadOpenTasks(polling = false, onSuc: () => void = noop, onErr: () => void = noop): void {
        if (!this.loading) {
            this.loading = true;
            this.transportLayer.getOpenTasks(
                action((data) => {
                    data.forEach((vo) => {
                        if (!!this.tasks[vo.task_id]) {
                            this.tasks[vo.task_id].updateFromVo(vo);
                        } else {
                            extendObservable(this.tasks, { [vo.task_id]: new Task(this, vo) });
                        }
                    });
                    onSuc();
                }),
                (error) => {
                    console.error('Could not load tasks from server.', error);
                    onErr();
                },
                action(() => {
                    this.loading = false;
                }),
            );
        }
        if (polling) {
            this.startPolling();
        }
    }

    @action loadUserTasks(userId: string, onSuc: () => void = noop, onErr: () => void = noop): void {
        this.transportLayer.getUserTasks(
            userId,
            action((data) => {
                data.forEach((vo) => {
                    if (!!this.tasks[vo.task_id]) {
                        this.tasks[vo.task_id].updateFromVo(vo);
                    } else {
                        extendObservable(this.tasks, { [vo.task_id]: new Task(this, vo) });
                    }
                });
                onSuc();
            }),
            (error) => {
                console.error('Could not load user tasks from server.', error);
                onErr();
            },
        );
    }

    @action loadTask(taskId: string): void {
        this.transportLayer.getTask(
            taskId,
            action((vo) => {
                if (!!this.tasks[vo.task_id]) {
                    this.tasks[vo.task_id].updateFromVo(vo);
                } else {
                    extendObservable(this.tasks, { [vo.task_id]: new Task(this, vo) });
                }
            }),
            (error) => {
                console.error('Could not load user tasks from server.', error);
            },
        );
    }

    resolveTask(taskId: string): Task {
        if (!this.tasks[taskId]) {
            extendObservable(this.tasks, { [taskId]: new Task(this) });
        }

        return this.tasks[taskId];
    }

    startPolling(): void {
        if (!this.polling) {
            this.polling = true;
            this.timer = setInterval(() => {
                if (!this.loading) {
                    this.loadOpenTasks();
                }
            }, config.pollingInterval);
        }
    }

    // TODO: note that this is returning open tasks only right now -- need to see if this is what we want going forward
    @computed get all(): Array<Task> {
        return Object.keys(this.tasks)
            .map((id) => this.tasks[id])
            .filter((task) => task.loaded === true);
    }

    @computed get open(): Array<Task> {
        return this.all.filter((task) => task.status !== 3);
    }

    @computed get openMarketTasks(): Array<Task> {
        return this.open.filter((task) => task.pipeline == Pipeline.GLOBAL_MARKET);
    }

    @computed get openUserTasks(): Array<Task> {
        return this.open.filter((task) => task.pipeline == Pipeline.GLOBAL_USER);
    }

    @computed get openUserMarketTasks(): Array<Task> {
        return this.open.filter((task) => task.pipeline == Pipeline.USER_MARKET);
    }

    @computed get openUserUserTasks(): Array<Task> {
        return this.open.filter((task) => task.pipeline == Pipeline.USER_USER);
    }

    @action stopPolling(): void {
        if (this.loading) {
            if (this.fetchRef !== null) {
                this.fetchRef.cancel('Get tasks request aborted!');
                this.fetchRef = null;
            }
            this.loading = false;
        }
        if (this.polling) {
            if (this.timer !== null) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.polling = false;
        }
    }

    @action updateTask(
        taskId: string,
        status?: number,
        resolution?: number,
        deadline?: Date,
        reviewer?: string,
        onSuc: () => void = noop,
    ): void {
        this.transportLayer.updateTask(taskId, { status, resolution, deadline, reviewer }, onSuc, noop);
    }

    @action comment(taskId: string, content: string, reviewer: string, onSuc: () => void = noop): void {
        this.transportLayer.comment(taskId, { content, reviewer }, onSuc, noop);
    }
}
