import React, { useEffect } from 'react';
import { TrendingMarketCard } from '../../components';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { Market } from '../../stores/domain/Market';
import { Box, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'flex-start',
        },
        content: {
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: theme.spacing(1),
        },
        marketCard: {
            width: 250,
            margin: theme.spacing(1),
        },
        header: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        subheader: {
            marginBottom: theme.spacing(2),
        },
    }),
);

const RealtimePane = observer(() => {
    const classes = useStyles();
    const { marketStore } = useStores();

    const marketDisplayCount = 12;
    const allMarketsPollSecs = 30;
    const marketPollSecs = 10;

    useEffect(() => {
        marketStore.startPolling(
            allMarketsPollSecs * 1000,
            () => {
                const allMarkets = marketStore.all;
                allMarkets.forEach((m) => m.stopPolling());

                // Get top markets by volume.
                const marketsOfInterest: Set<Market> = new Set();

                const marketsByRecentVolume = allMarkets
                    .sort((a, b) => b.recentVolume - a.recentVolume)
                    .slice(0, marketDisplayCount);

                marketsByRecentVolume.forEach((m) => marketsOfInterest.add(m));

                const marketsByIntervalVolume = allMarkets
                    .filter((m) => m.volumeDifference !== null)
                    .sort((a, b) => (b.volumeDifference ?? 0) - (a.volumeDifference ?? 0))
                    .slice(0, marketDisplayCount);

                marketsByIntervalVolume.forEach((m) => marketsOfInterest.add(m));

                // Consider if there is a better way to add delays.
                let i = 0;
                marketsOfInterest.forEach((m) => {
                    setTimeout(
                        () => m.startPolling(marketPollSecs * 1000),
                        ((i * marketPollSecs) / (2 * marketDisplayCount)) * 1000,
                    );

                    i += 1;
                });
            },
            () => null,
        );

        marketStore.loadAuditLogs();
    }, [marketStore]);

    const allMarkets = marketStore.all;
    const marketsByRecentVolume = allMarkets
        .sort((a, b) => b.recentVolume - a.recentVolume)
        .slice(0, marketDisplayCount);

    const marketsByIntervalVolume = allMarkets
        .filter((m) => m.volumeDifference !== null)
        .sort((a, b) => (b.volumeDifference ?? 0) - (a.volumeDifference ?? 0))
        .slice(0, marketDisplayCount);

    return (
        <Box className={classes.root}>
            <Box className={classes.content}>
                <Box flexGrow={1}>
                    <Typography variant={'h5'} className={classes.header}>
                        Top Volume in the Past 30 Seconds
                    </Typography>
                    <Typography className={classes.subheader}>
                        Plots shown over the last fifteen minutes of the market.
                    </Typography>
                    <Grid container spacing={3} alignContent="center" justify={'flex-start'}>
                        {marketsByIntervalVolume.map((m, i) => (
                            <div className={classes.marketCard} key={i}>
                                <TrendingMarketCard market={m} shortHorizon={true} />
                            </div>
                        ))}
                    </Grid>
                    <Typography variant={'h5'} className={classes.header}>
                        Top Volume in the Past 24 Hours
                    </Typography>
                    <Typography className={classes.subheader}>Plots shown over the full market lifetime.</Typography>
                    <Grid container spacing={3} alignContent="center" justify={'flex-start'}>
                        {marketsByRecentVolume.map((m, i) => (
                            <div className={classes.marketCard} key={i}>
                                <TrendingMarketCard market={m} shortHorizon={false} />
                            </div>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
});

export default RealtimePane;
