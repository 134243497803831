import { action, computed, observable } from 'mobx';
import { Identifier, Comment, TaskVo, Trigger } from '../../models';
import TaskStore from '../tasks/tasks';
import moment from 'moment';

export enum Pipeline {
    GLOBAL_MARKET,
    GLOBAL_USER,
    USER_MARKET,
    USER_USER,
    UNIVERSAL,
}

export class Task {
    @observable
    taskId = '';

    @observable
    pipelineInt = 0;

    @observable
    identifiers: Identifier | undefined;

    @observable
    triggers: Trigger[] = [];

    @observable
    comments: Comment[] = [];

    @observable
    priority = 0;

    @observable
    status = 0;

    @observable
    reviewer = '';

    @observable
    deadline: string | undefined;

    @observable
    lastUpdateTimestampString = '';

    @observable
    createdTimestampString = '';

    @observable
    resolution: number | undefined;

    @observable
    loaded = false;

    store: TaskStore | null = null;

    constructor(store: TaskStore, vo: TaskVo | undefined = undefined) {
        this.store = store;
        if (!!vo) {
            this.updateFromVo(vo);
        }
    }

    @computed get createdTimestamp(): Date {
        return moment.utc(this.createdTimestampString).toDate();
    }

    @computed get lastUpdateTimestamp(): Date {
        return moment.utc(this.lastUpdateTimestampString).toDate();
    }

    @computed get pipeline(): Pipeline {
        switch (this.pipelineInt) {
            case 0:
                return Pipeline.GLOBAL_MARKET;
            case 1:
                return Pipeline.GLOBAL_USER;
            case 2:
                return Pipeline.USER_MARKET;
            case 3:
                return Pipeline.USER_USER;
            default:
                return Pipeline.UNIVERSAL;
        }
    }

    @computed get pipelineString(): string {
        switch (this.pipelineInt) {
            case 0:
                return 'Global Market';
            case 1:
                return 'Global User';
            case 2:
                return 'User Market';
            case 3:
                return 'User User';
            default:
                return 'Universal';
        }
    }

    @computed get statusText(): string {
        switch (this.status) {
            case 0:
                return 'Pending';
            case 1:
                return 'In Progress';
            case 2:
                return 'Stall';
            case 3:
                return 'Resolved';
            default:
                return 'Unknown';
        }
    }

    @computed get resolutionText(): string {
        switch (this.resolution) {
            case 0:
                return 'No Action';
            case 1:
                return 'CMIR Increase';
            case 2:
                return 'Mild Alert';
            case 3:
                return 'Disciplinary Procedure';
            default:
                return 'None';
        }
    }

    @action
    updateFromVo(vo: TaskVo): Task {
        this.taskId = vo.task_id;
        this.pipelineInt = vo.pipeline;
        this.identifiers = vo.identifiers;
        this.comments = vo.comments;
        this.triggers = vo.triggers;
        this.status = vo.status;
        this.priority = vo.priority;
        this.lastUpdateTimestampString = vo.last_update_timestamp;
        this.createdTimestampString = vo.created_timestamp;
        this.reviewer = vo.reviewer;
        this.resolution = vo.resolution;
        this.loaded = true;
        return this;
    }
}
