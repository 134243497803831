import { action, computed, observable } from 'mobx';
import { OrderBookEntryMap, OrderBookEntryVoNew } from '../../models';
import MarketStore from '../market';
import { OrderBookEntry } from './OrderbookEntry';
import { range } from '../../utils/number';
import { SnapshotDTO, WsMessage, WsUnsubscribeCallback } from '../../models/ws';
import log from 'loglevel';

export class OrderBook {
    polling = false;
    loading = false;

    @observable
    orderBookEntrys: OrderBookEntryMap = {};

    unSubscribe: WsUnsubscribeCallback | null = null;
    store: MarketStore;

    @observable marketId = '';

    constructor(store: MarketStore, marketId: string) {
        this.store = store;
        this.marketId = marketId;
    }

    wsSubscribe(): void {
        this.unSubscribe = this.store.ws.subscribe(
            { channels: ['orderbook'], market_id: this.marketId },
            this.wsHandleMessage.bind(this),
        );
    }

    parseRequest(data: OrderBookEntryVoNew): void {
        log.debug('Orderbook response message is', data);
        this.reset();
        data?.yes?.forEach((entry: [number, number]): void => {
            this.resolveOrderBookEntry(true, entry[0]).updateFromVo({
                is_yes: true,
                price: entry[0],
                count: entry[1],
            });
        });
        data?.no?.forEach((entry: [number, number]) => {
            this.resolveOrderBookEntry(false, entry[0]).updateFromVo({
                is_yes: false,
                price: entry[0],
                count: entry[1],
            });
        });
    }

    @action private wsHandleMessage(msg: WsMessage): void {
        log.debug('Orderbook wsHandleMessage message is', msg);
        if (msg.type === 'orderbook_snapshot') {
            this.reset();
        } else if (msg.type !== 'orderbook_delta') {
            return;
        }

        const payload = msg.msg as SnapshotDTO;
        payload?.yes?.forEach((entry: [number, number]): void => {
            this.resolveOrderBookEntry(true, entry[0]).updateFromVo({
                is_yes: true,
                price: entry[0],
                count: entry[1],
            });
        });
        payload?.no?.forEach((entry: [number, number]) => {
            this.resolveOrderBookEntry(false, entry[0]).updateFromVo({
                is_yes: false,
                price: entry[0],
                count: entry[1],
            });
        });
    }

    @computed
    get all(): Array<OrderBookEntry> {
        return Object.keys(this.orderBookEntrys).map((key) => this.orderBookEntrys[key]);
    }

    @computed get noEntries(): Array<OrderBookEntry> {
        return this.all
            .filter((orderBookEntry) => !orderBookEntry.isYes)
            .sort((a, b) => a.priceInCents - b.priceInCents);
    }

    @computed get yesEntries(): Array<OrderBookEntry> {
        return this.all
            .filter((orderBookEntry) => orderBookEntry.isYes)
            .sort((a, b) => b.priceInCents - a.priceInCents);
    }

    @computed get noBidInCents(): number | undefined {
        const existingBid = this.noEntries
            .filter((entry) => entry.count > 0)
            .reduce((prev, cur: OrderBookEntry) => Math.max(prev, cur.priceInCents), 0);
        return existingBid === 0 ? undefined : existingBid;
    }

    @computed get yesBidInCents(): number | undefined {
        const existingBid = this.yesEntries
            .filter((entry) => entry.count > 0)
            .reduce((prev, cur: OrderBookEntry) => Math.max(prev, cur.priceInCents), 0);
        return existingBid === 0 ? undefined : existingBid;
    }

    @action
    reset(): void {
        const emptyOrderbook: OrderBookEntryMap = {};
        range(1, 99).forEach((price) => {
            // Pad / reset entries in orderbook so we have the view for all prices
            emptyOrderbook[`yes-${price}`] = new OrderBookEntry({ is_yes: true, price: price, count: 0 });
            emptyOrderbook[`no-${price}`] = new OrderBookEntry({ is_yes: false, price: price, count: 0 });
        });
        this.orderBookEntrys = emptyOrderbook;
    }

    @action
    resolveOrderBookEntry(isYes: boolean, price: number): OrderBookEntry {
        const index = `${isYes ? 'yes' : 'no'}-${price}`;
        if (!this.orderBookEntrys[index]) {
            this.orderBookEntrys[index] = new OrderBookEntry({ is_yes: isYes, price: price, count: 0 });
        }
        return this.orderBookEntrys[index];
    }

    @action dispose(): void {
        this.unSubscribe && this.unSubscribe();
        Object.keys(this.orderBookEntrys).forEach((key) => this.orderBookEntrys[key].dispose());
        this.orderBookEntrys = {};
    }
}
