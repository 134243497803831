import React from 'react';
import { DashboardComponent } from '../../../components';
import Typography from '@material-ui/core/Typography';
import { IgrFinancialChart } from 'igniteui-react-charts';
import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { Market } from '../../../stores/domain/Market';

const METRIC_OPTIONS: any = {
    volume: { label: 'Trade Volume', factor: 1 },
    openInterest: { label: 'Open Interest', factor: 1 },
};

interface StatsGraphProps {
    market: Market;
}

const StatsGraph = ({ market }: StatsGraphProps): JSX.Element => {
    const stats = market.statsHistory;
    const [metric, setMetric] = useState<string>('volume');

    const content = stats.map((stat) => ({
        time: new Date(stat.ts * 1000),
        close: metric == 'volume' ? stat.volume : stat.openInterest,
    }));

    return (
        <DashboardComponent mt={1} width={'100%'} display={'flex'} flexDirection={'column'} minHeight="40vh">
            <Box mt={1} mb={1} display={'flex'} alignItems={'center'}>
                <Typography variant={'h6'}>Stats</Typography>
                <Box ml={2}>
                    <TextField
                        select
                        label={'Metric'}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        value={metric}
                        onChange={(e) => setMetric(e.target.value as string)}
                    >
                        {Object.keys(METRIC_OPTIONS).map((option) => (
                            <MenuItem key={`select-user-metric-${option}`} value={option}>
                                {METRIC_OPTIONS[option].label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
            <Box mt={2}>
                <IgrFinancialChart
                    width="100%"
                    height="100%"
                    dataSource={content}
                    chartType="line"
                    isToolbarVisible={true}
                    crosshairsDisplayMode="Vertical"
                    crosshairsSnapToData={false}
                    crosshairsAnnotationEnabled={false}
                    chartTitle={METRIC_OPTIONS[metric].label}
                />
            </Box>
        </DashboardComponent>
    );
};

export default observer(StatsGraph);
