type KALSHI_ENVIRONMENT = 'dev' | 'staging' | 'alpha';

interface Config {
    env: KALSHI_ENVIRONMENT;
    exchangeApiUrl: string;
    exchangeFrontendUrl: string;
    exchangeWsUrl: string;
    ktssApiUrl: string;
    pollingInterval: number;
    requestTimeoutMs: number;
    sessionLength: number;
}

const config: Config = {
    env: (process.env.REACT_APP_ENVIRONMENT as KALSHI_ENVIRONMENT) || 'dev',
    exchangeFrontendUrl: process.env.REACT_APP_EXCHANGE_FRONTEND_URL || 'https://staging.kalshi.co',
    exchangeApiUrl: process.env.REACT_APP_EXCHANGE_API_URL || 'http://localhost:8080',
    exchangeWsUrl: process.env.REACT_APP_EXCHANGE_WS_URL || 'ws://localhost:8080',
    ktssApiUrl: process.env.REACT_APP_KTSS_API_URL || 'http://localhost:8081',
    pollingInterval: Number(process.env.REACT_APP_POLLING_INTERVAL) || 2000,
    requestTimeoutMs: Number(process.env.REACT_APP_REQUEST_TIMEOUT_MS) || 5000,
    sessionLength: 2590000,
};

export default config;
