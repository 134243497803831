import React from 'react';
import clsx from 'clsx';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { toNumber } from '../utils/casing';
import { OrderBookEntry } from '../stores/domain/OrderbookEntry';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        yes: {
            color: theme.palette.success.main,
        },
        no: {
            color: theme.palette.error.main,
        },
        text: {
            fontWeight: 'bold',
            fontSize: '13pt',
        },
        items: {
            marginTop: theme.spacing(1),
        },
    }),
);

interface OfferListProps {
    orders: Array<OrderBookEntry>;
    variant: 'yes' | 'no';
    [extras: string]: any;
}

function OfferList({ orders, variant, ...extras }: OfferListProps) {
    const classes = useStyles();

    return (
        <Box width={240} flexDirection="column" justifyContent="center" {...extras}>
            <Box display="flex" flexDirection="row" justifyContent="center">
                <Typography component="h5" variant="h5" className={classes[variant]}>
                    {variant.toLocaleUpperCase()}
                </Typography>
            </Box>
            <Box mt={3} display="flex" justifyContent="space-between">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography className={classes.text}>price</Typography>
                    {orders.map((order, idx) => (
                        <Typography className={clsx(classes.items, classes.text)} key={`price-${idx}`}>
                            {order.isYes ? `¢${toNumber(order.price, 0)}` : `¢${toNumber(100 - order.price, 0)}`}
                        </Typography>
                    ))}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography className={classes.text}>contract</Typography>
                    {orders.map((order, idx) => (
                        <Typography className={clsx(classes.items, classes.text)} key={`contract-${idx}`}>
                            {`x${order.count}`}
                        </Typography>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default OfferList;
