import { KiraStats, OrderMap, TradeMap, UsersMap } from '../../models';
import { action, observable } from 'mobx';
import { RootStore } from '../root';
import { TransportLayer } from '../../transport';
import { Trade } from '../domain/Trade';
import { Order } from '../domain/Order';

const noop = (): null => null;

export default class TraderStore {
    polling = false;

    @observable traders: UsersMap = {};
    @observable trades: TradeMap = {};
    @observable orders: OrderMap = {};
    @observable userStats: Array<KiraStats> = [];

    root: RootStore;

    constructor(rootStore: RootStore) {
        this.root = rootStore;
    }

    get transportLayer(): TransportLayer {
        return this.root.transportLayer;
    }

    @action loadTraderStats(userId: string): void {
        this.transportLayer.getGlobalUserStats(
            userId,
            '1y',
            action((data) => {
                this.userStats = data;
            }),
            noop,
            noop,
        );
    }

    @action loadUser(userId: string): void {
        this.transportLayer.adminGetUser(
            userId,
            action((userVo) => {
                if (!this.traders[userId]) {
                    this.traders[userId] = userVo;
                } else {
                    this.traders[userId] = userVo;
                }
                this.transportLayer.getUserTrades(
                    userId,
                    action((data) => {
                        this.trades[userId] = data.map((vo) => new Trade(vo));
                    }),
                );
                this.transportLayer.getUserOrders(
                    userId,
                    action((data) => {
                        this.orders[userId] = data
                            .filter((o) => o.user_id == userId)
                            .map((vo) => new Order(this.root.marketStore, vo));
                    }),
                );
            }),
            (err) => {
                console.error('Could not load user from server.', err);
            },
        );
    }
}
