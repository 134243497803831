import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AuditEntry } from '../../../models';

interface OperationLogProps {
    log: AuditEntry;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        green: {
            color: '#3BC89B',
        },
        red: {
            color: 'F45531',
        },
    }),
);

const getLogText = (log: AuditEntry): string => {
    let message = '';
    console.log(log.type);
    switch (log.type) {
        case 'market_position_updated':
            message += `[MARKET POSITION UPDATED] traderID:${log.user_id} - marketID:${log.market_id} - position:${log.data.position}`;
            break;
        case 'market_created': {
            message += `[MARKET CREATE] close date:${log.data.close_date}`;
            break;
        }
        case 'trade_executed':
            message += `[TRADE EXECUTED]`;
            break;
        case 'provisional_trade':
            message += `[TRADE PROVISIONALLY EXECUTED] tradeID:${log.type} - takerOrderID:${log.data.taker_order_id} - makerOrderID:${log.data.maker_order_id}`;
            break;
        case 'order_placed': {
            message += `[ORDER SUBMIT] traderID:${log.user_id} - marketID:${log.market_id} - position:${
                log.data.place_count
            } - ${log.data.is_yes ? 'YES' : 'NO'} - contracts:${log.data.place_count} - price:${log.data.price}`;
            break;
        }
        case 'order_canceled': {
            message += `[ORDER CANCEL] orderID:${log.data.order_id} - traderID:${log.user_id}`;
            break;
        }
        case 'market_determined': {
            message += `[MARKET SETTLE] marketID:${log.market_id} - settle position:${log.data.result ? 'YES' : 'NO'}`;
            break;
        }
        case 'market_deactivated': {
            message += `[MARKET CLOSE] marketID:${log.market_id}`;
            break;
        }
        case 'order_updated': {
            message += `[ORDER REDUCE] orderID:${log.data.order_id} - traderID:${log.user_id} - count:${log.data.new_place_count}`;
            break;
        }
        // case 'user add bank': {
        //     message += `[BANK CREATE] traderID:${log.UserId} -  account:${log.ReqRoutingNumber} - display name:${log.ReqDisplayName}`;
        //     break;
        // }
        // case 'user remove bank': {
        //     message += `[BANK REMOVE] traderID:${log.UserId} - bankAccountID:${log.ReqBankId} - account:${log.ReqRoutingNumber} - display name:${log.ReqDisplayName}`;
        //     break;
        // }
        case 'user_created': {
            message += `[USER REGISTER] email:${log.data.email}`;
            break;
        }
        default:
            message = `[${log.type.toUpperCase()}]`;
            break;
    }
    return message;
};

const getCreatedEntitiesText = (log: AuditEntry): string => {
    let message = '';
    if (log.type == 'market_created') {
        message += `[MARKET CREATE] marketID:${log.market_id} - close date:${log.data.expiration_date}`;
    }
    if (log.type == 'order_placed') {
        if (message.length > 0) {
            message += '. ';
        }
        message += `[ORDER CREATE] orderID:${log.data.order_id} - traderID:${log.user_id} - marketID:${
            log.market_id
        } - position:${log.data.place_count} - ${log.data.is_yes ? 'YES' : 'NO'} - contracts:${
            log.data.place_count
        } - price:${log.data.price}`;
    }
    // if (log.type == '') {
    //     if (message.length > 0) {
    //         message += '. ';
    //     }
    //     message += `[TRANSFER CREATE] transferID:${log.CreatedTransfer} - amount:${toMoney(
    //         log.ReqAmount / 10,
    //     )} - bankAccountID:${log.ReqBankId}`;
    // }
    //
    // if (!!log.CreatedBank) {
    //     if (message.length > 0) {
    //         message += '. ';
    //     }
    //     message += `[ACCOUNT CREATE] bankAccountID:${log.CreatedBank} - account:${log.ReqRoutingNumber} - display name:${log.ReqDisplayName}`;
    // }
    return message;
};

const getCreatedTrades = (log: AuditEntry): string => {
    let message = '';
    if (log.type == 'trade_executed') {
        if (message.length > 0) {
            message += '. ';
        }
        message += `[TRADE EXECUTE] tradeID:${log.type} - takerOrderID:${log.data.taker_order_id} - makerOrderID:${log.data.maker_order_id}`;
    }
    return message;
};

const zeroPad = (numString: string, places: number) => numString.padStart(places, '0');

const OperationLog = ({ log }: OperationLogProps) => {
    const logText = getLogText(log);
    const entitiesText = getCreatedEntitiesText(log);
    const createdTradesText = getCreatedTrades(log);

    return (
        <Box mt={1} p={0.5} display={'flex'} alignItems={'flex-start'} style={{ border: '1px solid black' }}>
            <Box>
                <Typography style={{ fontWeight: 'bold', flexShrink: 0 }}>
                    {`${
                        moment.utc(log.created_ts).isValid()
                            ? moment.utc(log.created_ts).local().format('YYYY-MM-DDTHH:mm:ss.SSS')
                            : 'Invalid Date'
                    }`}
                </Typography>
            </Box>
            <Box ml={2}>
                <Typography>{logText}.</Typography>
                {!!entitiesText && <Typography>{entitiesText}.</Typography>}
                {!!createdTradesText && <Typography>{createdTradesText}.</Typography>}
            </Box>
        </Box>
    );
};

export default observer(OperationLog);
