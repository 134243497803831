import { useMediaQuery } from 'react-responsive';

type Breakpoint = 'xs' | 'sm' | 'sm639px' | 'md' | 'md1059px' | 'lg' | 'xl';

const maxWidthForBreakpoint = {
    xs: 600,
    sm639px: 639,
    sm: 960,
    md1059px: 1059,
    md: 1280,
    lg: 1920,
    xl: 5000,
};

const useWidthDown = (breakpoint: Breakpoint): boolean => {
    return useMediaQuery({ maxWidth: maxWidthForBreakpoint[breakpoint] });
};

const down = (breakpoint: Breakpoint): string => {
    return '@media (max-width: ' + maxWidthForBreakpoint[breakpoint] + 'px)';
};

export default {
    isWidthDown: useWidthDown,
    down,
};
