import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/use-stores';

const RouteWithLayout = observer((props: any) => {
    const { layout: Layout, location, authenticated, component: Component, ...rest } = props;

    const { userStore } = useStores();

    if (authenticated && !userStore.user.user_id) {
        return <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />;
    }

    return (
        <Route
            {...rest}
            render={(matchProps): JSX.Element => {
                return (
                    <Layout>
                        <Component {...matchProps} />
                    </Layout>
                );
            }}
        />
    );
});

export default RouteWithLayout;
