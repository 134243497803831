import React from 'react';
import { StaticContext } from 'react-router';
import { Switch, RouteComponentProps } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Dashboard as DashboardLayout, Clean as CleanLayout } from './layouts';
import { MarketPage, UserPage, Tasks, SignIn, AuditPage, TaskPane, RealtimePane } from './pages';
import { observer } from 'mobx-react';

type LocationState = {
    from: string;
};

export type RouteProps = RouteComponentProps<{}, StaticContext, LocationState>;

const Routes = observer((props: any) => {
    return (
        <Switch>
            <RouteWithLayout
                authenticated
                exact
                path="/markets/:marketId"
                component={MarketPage}
                layout={DashboardLayout}
                {...props}
            />
            <RouteWithLayout
                authenticated
                exact
                path="/users/:userId"
                component={UserPage}
                layout={DashboardLayout}
                {...props}
            />
            <RouteWithLayout authenticated exact path="/audit" component={AuditPage} layout={CleanLayout} {...props} />
            <RouteWithLayout
                authenticated
                exact
                path="/realtime_pane"
                component={RealtimePane}
                layout={DashboardLayout}
                {...props}
            />
            <RouteWithLayout exact path="/login" component={SignIn} layout={CleanLayout} {...props} />
            <RouteWithLayout
                authenticated
                exact
                path={['/tasks/:taskId']}
                component={TaskPane}
                layout={DashboardLayout}
                {...props}
            />
            <RouteWithLayout
                authenticated
                exact
                path={['/', '/tasks']}
                component={Tasks}
                layout={DashboardLayout}
                {...props}
            />
        </Switch>
    );
});

export default Routes;
