import { RootStore } from '../root';
import TransportLayer from '../../transport/transport_layer';
import { action, computed, observable } from 'mobx';
import { UserVo } from '../../models';
import { CancelTokenSource } from 'axios';
import { setLocalStorageWithExpiration } from '../../utils/storage';
import { CSRF_LOCAL_STORAGE_KEY, USER_ID_LOCAL_STORAGE_KEY } from '../../constants/session';

const emptyUser: UserVo = {
    city: '',
    country: '',
    create_date: undefined,
    email: '',
    first_name: '',
    user_id: '',
    is_blocked: false,
    is_closed: false,
    is_verified: false,
    last_name: '',
    per_market_risk_limit: 0,
    postal_code: '',
    state: '',
    street1: '',
    street2: '',
    deposits: 0,
    withdrawals: 0,
    maker_trade_cost: 0,
    maker_trade_count: 0,
    taker_trade_cost: 0,
    taker_trade_count: 0,
    settle_trade_cost: 0,
    settle_trade_count: 0,
    net_cost: 0,
    net_count: 0,
    fee_cost: 0,
    number_of_markets_traded: 0,
    watchlist: [],
    birth_date: '',
    use_bid_ask: false,
    finished_fre: false,
    country_code: '',
    area_code: '',
    phone_number: '',
};

export default class UserStore {
    root: RootStore;

    @observable user: UserVo = emptyUser;
    @observable loaded = false;
    @observable loading = false;
    polling = false;
    timerId: NodeJS.Timeout | null = null;
    fetchRef: CancelTokenSource | null = null;

    constructor(rootStore: RootStore) {
        if (localStorage.getItem('userId') !== null) {
            this.user.user_id = JSON.parse(localStorage.userId).value;
        }
        this.root = rootStore;
    }

    get transportLayer(): TransportLayer {
        return this.root.transportLayer;
    }

    @action login(email: string, password: string): void {
        this.transportLayer.login(
            { email, password },
            action((response) => {
                this.setUserId(response.user_id);
                this.load();
            }),
            (err) => {
                console.log(err);
            },
        );
    }

    @action load(): void {
        if (!this.user.user_id) {
            return;
        }
        this.loading = true;

        this.transportLayer.adminGetUser(
            this.user.user_id,
            action((user) => {
                this.setUser(user);
            }),
            (err) => console.log(err),
        );
    }

    @computed get isLoggedIn(): boolean {
        return !!this.user.user_id && !!this.user.email;
    }

    @action dispose(): void {
        if (this.loading) {
            if (this.fetchRef !== null) {
                this.fetchRef.cancel('User fetch request aborted!');
                this.fetchRef = null;
            }
            this.loading = false;
        }
        if (this.polling) {
            if (this.timerId !== null) {
                clearInterval(this.timerId);
                this.timerId = null;
            }
            this.polling = false;
        }
        this.user = emptyUser;
    }

    // This is the official place where we identify the logged users
    @action setUserId(val: string): void {
        this.user.user_id = val;
        setLocalStorageWithExpiration(USER_ID_LOCAL_STORAGE_KEY, val);
    }

    // This is the official place where we dispose the current userId
    @action clearUserId(): void {
        this.user.user_id = '';
        localStorage.removeItem(USER_ID_LOCAL_STORAGE_KEY);
        localStorage.removeItem(CSRF_LOCAL_STORAGE_KEY);
    }

    @action setUser(val: UserVo): void {
        this.user = val;
    }
}
