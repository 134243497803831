import { action, computed, observable } from 'mobx';
import { Market } from './Market';
import { OrderStatus, OrderVo } from '../../models/order';
import MarketStore from '../market';

export class Order {
    @observable
    id = '';
    marketId = '';
    userId = '';

    @observable
    isYes = false;

    // price is in cents unit
    @observable
    price = 50;

    @observable
    createTs = '';

    @observable
    takerFillCount = 0;

    @observable
    takerFillCost = 0;

    @observable
    placeCount = 0;

    @observable
    decreaseCount = 0;

    @observable
    makerFillCount = 0;

    @observable
    fccCancelCount = 0;

    @observable
    closeCancelCount = 0;

    @observable
    status: OrderStatus = 'unknown';

    @observable
    reductionReason = '';

    @observable
    remainingCount = 0;

    @observable
    lastUpdateTs = '';

    @observable
    lastUpdateOp = '';

    @observable market: Market | null = null;

    store: MarketStore | null = null;

    constructor(store: MarketStore, vo: OrderVo | undefined = undefined, reductionReason = '') {
        this.store = store;
        this.reductionReason = reductionReason;
        if (!!vo) {
            this.updateFromRestVo(vo);
        }
    }

    /**
     * Update this order with information from the rest api /users/{userId}/orders
     */
    @action updateFromRestVo(vo: OrderVo): void {
        this.id = vo.order_id;
        //safe-guards are needed because the ip is not always returning all the fields
        if (!!vo.user_id) this.userId = vo.user_id;
        if (!!vo.market_id) {
            this.marketId = vo.market_id;
            if (!!this.store) {
                this.market = this.store.resolveMarket(this.marketId);
            }
        }
        this.isYes = vo.is_yes;
        this.price = vo.price;
        this.createTs = vo.create_ts;
        if (vo.taker_fill_count) {
            this.takerFillCount = vo.taker_fill_count || this.takerFillCount;
        }
        if (vo.taker_fill_cost) {
            this.takerFillCost = vo.taker_fill_cost;
        }
        if (vo.place_count) {
            this.placeCount = vo.place_count;
        }
        if (vo.decrease_count) {
            this.decreaseCount = vo.decrease_count;
        }
        if (vo.maker_fill_count) {
            this.makerFillCount = vo.maker_fill_count;
        }
        if (vo.fcc_cancel_count) {
            this.fccCancelCount = vo.fcc_cancel_count;
        }
        if (vo.close_cancel_count) {
            this.closeCancelCount = vo.close_cancel_count;
        }
        if (vo.remaining_count) {
            this.remainingCount = vo.remaining_count;
        }
        if (vo.last_update_op) {
            this.lastUpdateOp = vo.last_update_op;
        }
        this.status = vo.status || 'unknown';
    }

    @computed get asVo(): OrderVo {
        const vo: OrderVo = {
            close_cancel_count: this.closeCancelCount,
            create_ts: this.createTs,
            decrease_count: this.decreaseCount,
            fcc_cancel_count: this.fccCancelCount,
            order_id: this.id,
            is_yes: this.isYes,
            maker_fill_count: this.makerFillCount,
            place_count: this.placeCount,
            price: this.price,
            market_id: this.marketId,
            taker_fill_cost: this.takerFillCost,
            taker_fill_count: this.takerFillCount,
            user_id: this.userId,
            remaining_count: this.remainingCount,
            last_update_op: this.lastUpdateOp,
            status: this.status,
        };

        return vo;
    }

    @computed get restingContracts(): number {
        return this.placeCount - this.decreaseCount - this.makerFillCount - this.fccCancelCount - this.closeCancelCount;
    }

    @computed get filledContracts(): number {
        return this.makerFillCount + this.takerFillCount;
    }

    @computed get totalVolume(): number {
        return this.restingContracts + this.filledContracts;
    }

    @computed get priceInCents(): number {
        return this.price;
    }

    @computed get priceInDollar(): number {
        return this.priceInCents / 100;
    }

    @computed get costInDollar(): number {
        return this.restingContracts * this.priceInDollar;
    }

    @computed get potentialProfitInDollar(): number {
        return this.restingContracts - this.costInDollar;
    }

    @computed get tradedContracts(): number {
        return this.takerFillCount;
    }

    @computed get fullyFailed(): boolean {
        return this.placeCount === 0 && this.takerFillCount === 0 && this.reductionReason !== '';
    }

    @computed get fullyTraded(): boolean {
        return this.placeCount === 0;
    }

    @computed get partiallyTraded(): boolean {
        return this.takerFillCount > 0 && this.placeCount > 0;
    }
}
