import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DashboardComponent, OfferList, Page, ValueDisplay } from '../../components';
import { Summary, PriceGraph, StatsGraph, TradeReconstruction, Orderbook as OrderbookComponent } from './components';
import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';
import { Market } from '../../stores/domain/Market';
import { OrderBook } from '../../stores/domain/Orderbook';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            paddingBottom: theme.spacing(6),
            minHeight: '78vh',
        },
        dashboardWrap: {
            maxWidth: '75vw',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
    }),
);

interface SpacedGridProps {
    id: string;
    market: Market;
}

const SpacedGrid = observer(({ market, id }: SpacedGridProps): JSX.Element => {
    const { marketStore } = useStores();
    const orderBook: OrderBook = marketStore.resolveOrderBook(market.id || '');

    useEffect(() => {
        if (!!id) {
            marketStore.loadOrderbook(id);
        }
        return (): void => {
            orderBook.dispose();
        };
    }, [marketStore, id, orderBook]);

    const trades = marketStore.trades[market.id];

    return (
        <Box p={4}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Summary market={market} />
                </Grid>
                <Grid container item xs={6}>
                    <Grid style={{ display: 'flex' }} item xs={12}>
                        <DashboardComponent width={'100%'} minHeight="30vh">
                            <PriceGraph market={market} />
                        </DashboardComponent>
                    </Grid>
                    <Grid style={{ display: 'flex' }} item xs={12}>
                        <StatsGraph market={market} />
                    </Grid>
                </Grid>
                <Grid container item xs={6}>
                    <Grid style={{ display: 'flex' }} item xs={12}>
                        <DashboardComponent width={'100%'} minHeight="30vh">
                            <OrderbookComponent orderBook={orderBook} />
                        </DashboardComponent>
                    </Grid>
                    <Grid style={{ display: 'flex' }} item xs={12}>
                        <DashboardComponent width={'100%'} minHeight="30vh">
                            <TradeReconstruction trades={trades} />
                        </DashboardComponent>
                    </Grid>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    <Typography>Audit Log Panel</Typography>*/}
                {/*    /!*<AuditLogPanel logs={getStore().logs} />*!/*/}
                {/*</Grid>*/}
            </Grid>
        </Box>
    );
});

const MarketDashboard = observer(() => {
    const { marketId } = useParams();
    const { marketStore, taskStore } = useStores();
    const history = useHistory();
    const market = marketStore.resolveMarket(marketId || '');
    const classes = useStyles();

    useEffect(() => {
        if (!!marketId) {
            marketStore.loadMarket(marketId, true, null, true);

            market.stopPolling();

            return (): void => {
                market.dispose();
            };
        } else {
            history.push('/');
        }
    }, [marketStore, taskStore, market, marketId]);

    return (
        <div className={classes.dashboardWrap}>
            <SpacedGrid market={market} id={marketId} />
            {/*<InvestigationModal orders={} />*/}
        </div>
    );
});

export default MarketDashboard;
