import { TradeVo } from '../../models';
import { computed, observable } from 'mobx';
import { Order } from './Order';
import { TradeStatus } from '../../models/trade';

export class Trade {
    id = '';
    marketId = '';
    @observable
    orderId = '';
    @observable
    maker_order_id = '';
    @observable
    taker_order_id = '';
    @observable
    maker_user_id = '';
    @observable
    taker_user_id = '';
    @observable
    maker_create_date = '';
    @observable
    taker_create_date = '';

    // If the current user is taker or maker in this trade
    @observable
    is_yes_taker = false;

    @observable
    status: TradeStatus = 'unknown';

    @observable
    price = 0;
    @observable
    count = 0;
    @observable
    date = '';

    @observable
    order: Order | undefined = undefined;

    // @observable
    // market: Market | undefined = undefined;

    constructor(vo: TradeVo | undefined = undefined) {
        if (!!vo) {
            this.updateFromVo(vo);
        }
    }

    /**
     * Update this trade with information from the rest api /users/{userId}/trades
     */
    updateFromVo(vo: TradeVo): void {
        this.id = vo.id;
        if (!!vo.market_id) {
            this.marketId = vo.market_id;
        }
        if (!!vo.maker_order_id) {
            this.maker_order_id = vo.maker_order_id;
        }
        if (!!vo.taker_order_id) {
            this.taker_order_id = vo.taker_order_id;
        }
        this.maker_user_id = vo.maker_user_id;
        this.taker_user_id = vo.taker_user_id;
        this.maker_create_date = vo.maker_create_date;
        this.is_yes_taker = vo.is_yes_taker;
        this.status = vo.status;
        this.price = vo.price;
        this.count = vo.count;
        this.date = vo.create_date;
    }

    @computed get userIsYes(): boolean | undefined {
        return this.order?.isYes;
    }

    @computed get priceInDollar(): number {
        // trades are stored with value for yes bid on price column written in small cents unit
        return this.price / 100;
    }

    @computed get costInDollar(): number {
        return this.count * this.priceInDollar;
    }

    @computed get potentialProfitInDollar(): number {
        return this.count - this.costInDollar;
    }
}
