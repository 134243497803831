import typography from './typography';
import palette from './palette';
import breakpoints from './breakpoints';

const spacing = (x: number): string => `${x * 8}px`;

const theme = {
    typography,
    palette,
    spacing,
    breakpoints,
};

export default theme;
