import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useStores } from '../../../hooks/use-stores';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            height: '80%',
            width: '1px',
            backgroundColor: 'black',
        },
        title: {
            textAlign: 'left',
        },
    }),
);

interface CurrentTasksProps {
    userId: string;
    open: boolean;
    title: string;
    [extras: string]: any;
}

const CurrentTasks = ({ userId, open, title, ...extras }: CurrentTasksProps): JSX.Element => {
    const { taskStore } = useStores();

    const userTasks = taskStore.all.filter((task) => {
        return (
            task.identifiers?.user_id === userId ||
            task.identifiers?.user_id_large ||
            task.identifiers?.user_id_small === userId
        );
    });

    const displayTasks = userTasks.filter((task) => (open ? task.status !== 3 : task.status === 3));

    const rows = displayTasks.map((task) => {
        return (
            <TableRow key={`task-row-${task.taskId}`}>
                <TableCell>
                    <Link to={`/tasks/${task.taskId}`}>{`#${task.taskId.substr(0, 5)}`}</Link>
                </TableCell>
                <TableCell>{task.pipelineString}</TableCell>
                {open && <TableCell>{task.statusText}</TableCell>}
                <TableCell>{task.resolutionText}</TableCell>
            </TableRow>
        );
    });

    const classes = useStyles();

    return (
        <Box display={'flex'} flexDirection={'column'} style={{ width: '100%', maxHeight: '40vh' }}>
            <Typography variant={'h6'} className={classes.title}>
                {title}
            </Typography>
            <Box mt={2}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Task Id</TableCell>
                            <TableCell>Kind</TableCell>
                            {open && <TableCell>State</TableCell>}
                            <TableCell>Outcome</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: 'auto' }}>{rows}</TableBody>
                </Table>
            </Box>
        </Box>
    );
};

export default observer(CurrentTasks);
