import {
    Area,
    AreaChart,
    Coordinate,
    Point,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    TooltipPayload,
    ViewBox,
    YAxis,
} from 'recharts';
import { theme, Box, BoxProps, Typography } from '../components/DesignSystem';
import React, { useEffect } from 'react';
import { Market, MarketStats } from '../stores/domain/Market';
import ActiveDot from './ActiveDot';
import { asCents, asPaddedCents } from '../utils/caseing';

interface CustomTooltipProps {
    // activeMetrics: number;
    activeItem?: MarketStats;
    viewBox?: ViewBox;
    point?: Point;
    offset?: number;
    coordinate?: Coordinate;
    position?: Coordinate;
    payload?: ReadonlyArray<TooltipPayload>;
}

const CustomTooltip = ({ coordinate, offset, viewBox, activeItem, point }: CustomTooltipProps): JSX.Element => {
    if (
        coordinate === undefined ||
        offset === undefined ||
        activeItem === undefined ||
        viewBox === undefined ||
        point === undefined
    ) {
        return <div />;
    }

    return (
        <div
            style={{
                height: '56px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                borderRadius: '8px',
                border: '1px solid #FFFFFF',
                backgroundColor: theme.palette.text.whiteOverlay,
                padding: theme.spacing(1),
                boxSizing: 'border-box',
                WebkitUserSelect: 'none' /* Safari */,
                MozUserSelect: 'none' /* Firefox */,
                msUserSelect: 'none' /* IE10+/Edge */,
                userSelect: 'none' /* Standard */,
            }}
        >
            <Typography color={'signature.blue'} size={'tiny'}>
                {`No: ${activeItem.price === 0 ? '--¢' : asPaddedCents(activeItem.no, 2)}`}
            </Typography>
            <Typography color={'signature.green'} size={'tiny'}>
                {`Yes: ${activeItem.price === 0 ? '--¢' : asPaddedCents(activeItem.yes, 2)}`}
            </Typography>
        </div>
    );
};

interface CustomYAxisProps {
    isChartHovered: boolean;
}

const CustomYAxis = ({ isChartHovered }: CustomYAxisProps): JSX.Element => (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ height: '100%', boxSizing: 'border-box', position: 'absolute' }}
        p={0.5}
    >
        {Array.from({ length: 11 }, (_, i) => 100 - i * 10).map((price) => (
            <Typography
                key={`yaxis-${price}`}
                color={isChartHovered ? 'text.blackLite' : 'text.blackOverlay'}
                style={{ fontSize: '8px', lineHeight: '10px' }}
            >
                {price > 0 && price < 100 && asCents(price)}
            </Typography>
        ))}
    </Box>
);

interface ChartLabelProps {
    isChartHovered: boolean;
    isYAxisShown: boolean;
}

const ChartLabel = ({ isChartHovered, isYAxisShown }: ChartLabelProps): JSX.Element => (
    <Typography
        color={isChartHovered ? 'text.blackLite' : 'text.blackOverlay'}
        size="tiny"
        style={{ position: 'absolute', left: theme.spacing(isYAxisShown ? 4 : 2), top: theme.spacing(1) }}
    >
        Last traded price
        {isYAxisShown && ' for Yes'}
    </Typography>
);

interface BasicMarketChartProps extends BoxProps {
    data: Array<MarketStats>;
    market: Market;
    onMouseMove: (e: { activeTooltipIndex?: number } | undefined) => void;
    onMouseLeave: () => void;
    activeIndex: number;
    lineWidth?: number;
    width?: string | number;
    height: number;
    panelHeight: number;
    minimal?: boolean;
}

const BasicMarketChart = ({
    data,
    market,
    onMouseMove,
    onMouseLeave,
    lineWidth = 2,
    width = '100%',
    height,
    activeIndex,
    panelHeight,
    minimal = false,
    ...boxProps
}: BasicMarketChartProps): JSX.Element => {
    const area = React.useRef<Area>(null);
    //const tooltip = React.useRef<never>(null);
    const [point, setPoint] = React.useState<Point | undefined>(undefined);

    const showYAxis = true;

    useEffect(() => {
        if (activeIndex >= 0) {
            const point = area?.current?.props.points?.[activeIndex];
            if (!!point) {
                setPoint(point);
            }
        }
    }, [activeIndex]);

    const pointBelowMid = (point?.y || 0) < height / 2;
    const areaWidth = area?.current?.props?.width;
    const rightCornerOutsideView = areaWidth !== undefined && (point?.x || 0) + 32 >= areaWidth; //32 + 8
    const leftCornerOutsideView = areaWidth !== undefined && (point?.x || 0) - 32 < 0; //32 + 8
    const isMobileOrTablet = theme.breakpoints.isWidthDown('md');

    return (
        <Box style={{ position: 'relative' }} {...boxProps}>
            {!minimal && <ChartLabel isChartHovered={activeIndex !== -1} isYAxisShown={showYAxis} />}
            {!minimal && showYAxis && <CustomYAxis isChartHovered={activeIndex !== -1} />}
            <ResponsiveContainer width={width} height={height} className={'basicMarketChart'}>
                <AreaChart
                    data={data}
                    onMouseMove={onMouseMove}
                    onMouseLeave={onMouseLeave}
                    onTouchMove={onMouseMove}
                    onMouseUp={
                        isMobileOrTablet
                            ? onMouseLeave
                            : (): void => {
                                  return;
                              }
                    }
                    onTouchEnd={onMouseLeave}
                    syncId={'ChartsPanel'}
                    stackOffset="expand"
                    style={{ overflow: 'visible' }}
                    margin={{ left: 0, top: 0 }}
                >
                    <defs>
                        <linearGradient id="marketChart-colorYes" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#179f61" stopOpacity={0.3} />
                            <stop offset="95%" stopColor="#e2f3eb" stopOpacity={0.06} />
                        </linearGradient>
                        <linearGradient id="marketChart-colorNo" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#cadef0" stopOpacity={0.3} />
                            <stop offset="95%" stopColor="#156dbe" stopOpacity={0.9} />
                        </linearGradient>
                    </defs>
                    {activeIndex >= 0 && point != undefined && (
                        <g className="recharts-layer recharts-reference-line">
                            <line
                                x={point.x}
                                stroke={theme.palette.text.blackLite}
                                strokeWidth="1"
                                height={panelHeight}
                                fill="none"
                                fillOpacity="1"
                                x1={point.x}
                                y1={panelHeight}
                                x2={point.x}
                                y2="0"
                                className="recharts-reference-line-line"
                            />
                        </g>
                    )}
                    <YAxis type="number" domain={[0, 1]} stroke={theme.palette.text.blackLite} hide={true} />
                    <Area
                        ref={area}
                        type="linear"
                        dataKey="yes"
                        stroke={theme.palette.signature.green}
                        fill={minimal ? 'transparent' : 'url(#marketChart-colorYes)'}
                        strokeWidth={lineWidth}
                        isAnimationActive={false}
                        activeDot={false}
                        dot={
                            !minimal && (!market.isClosed || activeIndex) >= 0 ? (
                                <ActiveDot
                                    activeIndex={activeIndex === -1 ? data.length - 1 : activeIndex}
                                    topColor={theme.palette.signature.blue}
                                    bottomColor={theme.palette.signature.green}
                                    blink={activeIndex === -1}
                                />
                            ) : undefined
                        }
                        stackId="1"
                    />
                    <Area
                        type="linear"
                        dataKey="no"
                        stroke="#cadef0 0.3"
                        fill={minimal ? 'transparent' : 'url(#marketChart-colorNo)'}
                        strokeWidth={lineWidth}
                        isAnimationActive={false}
                        activeDot={false}
                        stackId="1"
                    />
                    {!minimal && (
                        <ReferenceLine
                            y={0.25}
                            strokeDasharray="2 8"
                            stroke={theme.palette.text.blackLite}
                            strokeWidth={1}
                        />
                    )}
                    <ReferenceLine
                        y={0.5}
                        strokeDasharray="6 4"
                        stroke={theme.palette.text.blackLite}
                        strokeWidth={1}
                    />
                    {!minimal && (
                        <ReferenceLine
                            y={0.75}
                            strokeDasharray="2 8"
                            stroke={theme.palette.text.blackLite}
                            strokeWidth={1}
                        />
                    )}
                    <Tooltip
                        wrapperStyle={{
                            visibility: activeIndex >= 0 ? 'visible' : 'hidden',
                            '&:before':
                                "content:''; display:'block'; width:0; height:0; position:'absolute'; border-top: '8px solid transparent'; border-bottom: '8px solid transparent'; border-right: '8px solid black'; left:-8px; top:7px;",
                        }}
                        position={
                            point === undefined
                                ? undefined
                                : {
                                      x: point.x + (rightCornerOutsideView ? -74 : leftCornerOutsideView ? +10 : -32),
                                      y: point.y + (pointBelowMid ? 10 : -66),
                                  }
                        }
                        content={
                            <CustomTooltip
                                activeItem={activeIndex >= 0 ? data[activeIndex] : undefined}
                                point={point}
                            />
                        }
                        cursor={false}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default BasicMarketChart;
