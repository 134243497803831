import * as React from 'react';
import { observer } from 'mobx-react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { OrderBookEntry } from '../../../stores/domain/OrderbookEntry';
import { OrderBook } from '../../../stores/domain/Orderbook';
import { OfferList } from '../../../components';
import { Box, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Market } from '../../../stores/domain/Market';
import { useStores } from '../../../hooks/use-stores';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            height: '80%',
            width: '1px',
            backgroundColor: 'black',
        },
        title: {
            textAlign: 'left',
        },
    }),
);

interface OfferBookProps {
    orderBook: OrderBook;
    [extras: string]: any;
}

const OrderBookPanel = ({ orderBook, ...extras }: OfferBookProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Box display={'flex'} flexDirection={'column'} style={{ width: '100%', maxHeight: '40vh' }}>
            <Typography variant={'h6'} className={classes.title}>
                Order Book
            </Typography>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent={'center'}
                height={250}
                width={'100%'}
                overflow="auto"
                {...extras}
            >
                <OfferList mr={10} variant="yes" orders={orderBook.yesEntries} />
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Divider classes={{ root: classes.divider }} orientation="horizontal" />
                </Box>
                <OfferList ml={10} variant="no" orders={orderBook.noEntries} />
            </Box>
        </Box>
    );
};

export default observer(OrderBookPanel);
