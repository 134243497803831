import React from 'react';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';

import ValueDisplay from '../../../components/ValueDisplay';
import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import { toNumber } from '../../../utils/casing';
import { Market } from '../../../stores/domain/Market';

interface SummaryProps {
    market: Market;
}

const Summary = ({ market }: SummaryProps): JSX.Element => {
    console.log(market);
    return (
        <Box ml={2}>
            <Typography variant={'h5'}>{market.title}</Typography>
            <Box mt={1} mb={1} display={'flex'}>
                <ValueDisplay label={'Status'} value={market.status} />
                <ValueDisplay ml={4} label={'Volume'} value={Math.max(0, market.volume)} />
                <ValueDisplay ml={4} label={'Last Price'} value={`${market.lastPrice / 100}¢`} />
                <ValueDisplay ml={4} label={'Open Interest'} value={toNumber(Math.max(0, market.openInterest))} />
                <ValueDisplay ml={4} label={'Yes Bid'} value={`${market.yesBid}¢`} />
                <ValueDisplay ml={4} label={'No Bid'} value={`${100 - market.yesAsk}¢`} />
                <ValueDisplay ml={4} label={'Created on'} value={moment.utc(market.createDate).local().format('lll')} />
                {(market.isDetermined || market.isSettled) && (
                    <ValueDisplay ml={4} label={'Outcome'} value={market.resultIsYes ? 'Yes' : 'No'} />
                )}
            </Box>
        </Box>
    );
};

export default observer(Summary);
