import { MarketStats } from '../stores/domain/Market';
import React from 'react';

interface ActiveDotProps {
    topColor: string;
    bottomColor: string;
    blink?: boolean;
    dotRadius?: number;
    cx?: number;
    cy?: number;
    activeIndex: number;
    index?: number;
    payload?: MarketStats;
}

const ActiveDot = ({
    index,
    activeIndex = -1,
    blink = false,
    topColor,
    bottomColor,
    dotRadius = 5,
    cx = 0,
    cy = 0,
}: ActiveDotProps): JSX.Element => {
    if (index !== activeIndex) {
        return <div />;
    }

    return (
        <svg>
            <defs>
                <linearGradient id="two-tone-active-dot" x1="0" x2="0" y1="0" y2="1">
                    <stop offset="0" stopColor={topColor} />
                    <stop offset="1" stopColor={bottomColor} />
                </linearGradient>
                <clipPath id="cut-off-right">
                    <rect x={`calc(100% - ${dotRadius * 5}px`} y="0" width={dotRadius * 5} height="100%" />
                </clipPath>
            </defs>
            <circle
                r={dotRadius}
                fill="url(#two-tone-active-dot)"
                clipPath={blink ? 'url(#cut-off-right)' : undefined}
                strokeWidth="1"
                className="recharts-dot"
                cx={cx}
                cy={cy}
            />

            <circle
                r={dotRadius}
                fill="url(#two-tone-active-dot)"
                clipPath={blink ? 'url(#cut-off-right)' : undefined}
                opacity="0.3"
                strokeWidth="1"
                className="recharts-dot"
                cx={cx}
                cy={cy}
            >
                {blink && (
                    <>
                        <animate
                            attributeName="r"
                            keyTimes="0;0.25;0.25;1"
                            values={`${dotRadius};${dotRadius * 5};${dotRadius};${dotRadius}`}
                            dur="4s"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="opacity"
                            keyTimes="0;0.25;0.25;1"
                            values="0.8;0;0;0"
                            dur="4s"
                            repeatCount="indefinite"
                        />
                    </>
                )}
            </circle>
        </svg>
    );
};

export default ActiveDot;
