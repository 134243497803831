import React from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { Card, Divider, Padding, theme, Typography } from './DesignSystem';

import BasicMarketChart from './BasicMarketChart';
import { noop } from '../utils/noop';
import { Market } from '../stores/domain/Market';
import { calculateStartTs, getBarSamplingPointsNumber } from '../utils/charting';
import { INITIAL_MARKET_STATS, MarketStats } from '../stores/domain/Market';
import { currentUnixTime } from '../utils/time';
import config from '../utils/config';

const useStyles = createUseStyles({
    root: {
        boxSizing: 'border-box',
        minWidth: '258px',
        width: '100%',
        position: 'relative',
        zIndex: 2,
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.surface.cream,
        },
    },
    card: {
        position: 'relative',
        zIndex: 1,
        display: 'flex !important',
        flexDirection: 'column',
    },
    picture: {
        width: 'calc(100% + 2px)',
        marginTop: '-1px',
        marginLeft: '-1px',
        backgroundSize: 'cover',
        backgroundTrending: 'center',
        flexGrow: 1,
        borderRadius: '4px 4px 0px 0px',
    },
    content: {
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    position: {
        transition: 'background-color 0.3s ease',
        width: '100%',
        boxSizing: 'border-box',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '0px 0px 4px 4px',
        '&:hover': {
            backgroundColor: theme.palette.surface.cream,
        },
    },
    priceContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

export interface TrendingMarketCardProps {
    market: Market;
    shortHorizon: boolean;
    onClick?: () => void;
}

const TrendingMarketCard = observer(({ onClick, market, shortHorizon }: TrendingMarketCardProps): JSX.Element => {
    const classes = useStyles();
    const volume = market.volume;
    const yesPrice = market.bestYesPriceInDollars;
    const noPrice = market.bestNoPriceInDollars;
    const isMobileOrTablet = theme.breakpoints.isWidthDown('sm');

    const marketCloseTs = Math.round(new Date(market.closeDate).getTime() / 1000);
    const marketOpenTs = Math.round(new Date(market.openDate).getTime() / 1000);
    const period = 'all';
    const end = Math.min(marketCloseTs, currentUnixTime());
    let start = calculateStartTs(end, marketOpenTs, period);

    if (shortHorizon) {
        // Plot no more than 15 minutes
        start = Math.max(start, Math.min(marketCloseTs, currentUnixTime()) - 15 * 60);
    }
    const data = market.statsHistory;

    const windowSize = end - start + 1;
    const samplingRate = windowSize / getBarSamplingPointsNumber(period);
    const expandedData: Array<MarketStats> = [];
    const sampling: MarketStats = {
        ...INITIAL_MARKET_STATS,
        ts: start,
    };
    for (let i = 0; i < data.length; i++) {
        if (data[i].ts >= start && data[i].ts < end) {
            while (sampling.ts <= data[i].ts) {
                expandedData.push({ ...sampling });
                sampling.ts += samplingRate;
            }
        }
        sampling.no = data[i].price != 0 ? 100 - data[i].price : 50;
        sampling.yes = data[i].price != 0 ? data[i].price : 50;
        sampling.price = data[i].price;
    }
    while (sampling.ts < end) {
        expandedData.push({ ...sampling });
        sampling.ts += samplingRate;
    }

    expandedData.push({
        ...sampling,
        ts: end,
    });

    return (
        <>
            <Card className={classes.root} onClick={onClick} style={{ height: 162 }}>
                <a href={`${config.exchangeFrontendUrl}/markets/${market.id}`} className={classes.card} target="blank">
                    <div className={classes.content}>
                        <Typography color={'text.blackOverlay'} size={'tiny16pxLH'}>
                            {`Volume: ${volume} (+${shortHorizon ? market.volumeDifference : market.recentVolume})`}
                        </Typography>
                        <Padding size="tiny" />
                        <Typography size={isMobileOrTablet ? 'tiny16pxLH' : undefined} weight={'dark'}>
                            {isMobileOrTablet ? market.title : market.tickerName}
                        </Typography>
                        <Divider variant={'horizontal'} crossMargin={theme.spacing(2)} />
                        <div className={classes.priceContainer}>
                            <Typography size="small" weight="dark" color={'signature.green'}>
                                {`Yes:`} <br /> {`${yesPrice}`}
                            </Typography>
                            <Padding size="small" />
                            <BasicMarketChart
                                data={expandedData}
                                market={market}
                                onMouseMove={noop}
                                onMouseLeave={noop}
                                activeIndex={-1}
                                width={100}
                                height={40}
                                panelHeight={50}
                                minimal
                                ml={-8}
                            />
                            <Typography size="small" weight="dark" color={'signature.blue'}>
                                {`No:`} <br /> {`${noPrice}`}
                            </Typography>
                        </div>
                    </div>
                </a>
            </Card>
        </>
    );
});

export default TrendingMarketCard;
