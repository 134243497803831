import React from 'react';
// import { PrivateNavbar, Footer } from '../components';
import { createUseStyles } from 'react-jss';
import { Navbar } from '../components';

const useStyles = createUseStyles({
    root: {
        paddingTop: 84,
        paddingBottom: 48,
        boxSizing: 'border-box',
        minHeight: '100vh',
        width: '100%',
    },
    navbar: {
        backgroundColor: 'white',
        width: '100%',
        position: 'fixed',
        zIndex: 70,
        top: 0,
        left: 0,
    },
    content: {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        maxWidth: '1152px',
    },
});

interface CleanProps {
    children: JSX.Element;
}

const Clean = ({ children }: CleanProps): JSX.Element => {
    const classes = useStyles();
    //const theme = useTheme();
    //const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    //    defaultMatches: true,
    //});

    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.content}>{children}</div>
        </div>
    );
};

export default Clean;
