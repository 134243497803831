import { colors, SimplePaletteColorOptions } from '@material-ui/core';
import { PaletteOptions, TypeBackground, TypeText } from '@material-ui/core/styles/createPalette';

const white = '#FFFFFF';
const black = '#000000';

const text: TypeText = {
    primary: black,
    secondary: '#86868B',
    disabled: colors.grey[600],
    hint: colors.indigo[600],
};

const primaryColor: SimplePaletteColorOptions = {
    contrastText: white,
    dark: '#078B4B',
    main: '#179F61',
    light: '#B4E6D0',
};

const backgroundColor: TypeBackground = {
    default: '#FFF',
    paper: '#FAFAFA',
};

const palette: PaletteOptions = {
    common: {
        white,
        black,
    },
    primary: primaryColor,
    secondary: {
        contrastText: primaryColor.main,
        dark: colors.grey[200],
        main: white,
        light: colors.red[300],
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: primaryColor.main,
        light: colors.green[400],
    },
    info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400],
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400],
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: '#F45531',
        light: colors.red[400],
    },
    text,
    background: backgroundColor,
    divider: colors.grey[200],
};

export { text, primaryColor, backgroundColor };
export default palette;
