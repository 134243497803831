import React from 'react';
import { Box, Typography } from '@material-ui/core';

interface ValueDisplayProps {
    label: string;
    value: any;
    variant?: 'h6' | 'body1';
    [rest: string]: any;
}

const ValueDisplay = ({ label, value, variant = 'body1', ...rest }: ValueDisplayProps) => {
    return (
        <Box display="flex" {...rest}>
            <Typography variant={variant} style={{ fontWeight: 'bold', flexShrink: 0 }}>{`${label}:`}</Typography>
            <Box ml={1}>
                <Typography variant={variant}>{value}</Typography>
            </Box>
        </Box>
    );
};

export default ValueDisplay;
